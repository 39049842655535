import { Expression } from '@angular/compiler';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FormlyDropdownValue } from '@intellio/shared/models';
import { FieldTypeConfig } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { FieldType } from '@ngx-formly/material/form-field';
import { Observable, Subject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'itc-formly-field-typeahead',
  template: `
    <span *ngIf="this.staticOptions; else multiUseCaseTypeahead">
      <input
        type="text"
        matInput
        [readonly]="props.readonly"
        [required]="props.required"
        [formControl]="control"
        [placeholder]="props.placeholder"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option?.label }}
        </mat-option>
      </mat-autocomplete>
    </span>
    <ng-template #multiUseCaseTypeahead>
      <span
        *ngIf="props.hasLocations !== undefined && props.hasLocations !== null"
      >
        <strong *ngIf="!props.hasLocations">
          You have no existing applications. Please create a new one by
          selecting 'A new system' above.
        </strong>

        <input
          type="text"
          matInput
          [formControl]="control"
          [matAutocomplete]="autoComp"
          [required]="props.required"
        />

        <mat-autocomplete #autoComp="matAutocomplete" [displayWith]="displayFn">
          <div *ngFor="let option of props.search$ | async">
            <mat-option [value]="option">
              {{ option?.label }}
            </mat-option>
          </div>
        </mat-autocomplete>
      </span>

      <span
        *ngIf="props.hasLocations === undefined || props.hasLocations === null"
      >
        <ng-select
          [id]="id"
          [clearable]="true"
          [multiple]="false"
          placeholder="{{ props.placeholder + (props.required ? ' *' : '') }}"
          [formControl]="control"
          [required]="props.required"
        >
          <cdk-virtual-scroll-viewport itemSize="42">
            <ng-option *ngFor="let option of results" [value]="option.value">{{
              option.label
            }}</ng-option>
          </cdk-virtual-scroll-viewport>
        </ng-select>
      </span>
    </ng-template>
  `,
})
export class TypeAheadTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  constructor(protected cd: ChangeDetectorRef) {
    super();
  }

  control: UntypedFormControl;
  onDestroy$ = new Subject<void>();
  results: FormlyDropdownValue[];
  jsonOptions: any[];
  reducedOptions: any;
  filteredOptions: Observable<any[]>;
  staticOptions: boolean = false;

  displayFn = (val) => val?.label;

  ngOnInit() {
    this.control = this?.formControl as UntypedFormControl;
    this.jsonOptions = this?.props.options as any[];
    if (this.props['staticOptions'] === true) {
      this.staticOptions = true;
      // when expressions run to update the options, use the updated value
      this.field.options.fieldChanges.subscribe((e) => {
        if (
          e.type === 'expressionChanges' &&
          e.field.key === this.field.key &&
          e.property === 'props.options'
        ) {
          this.jsonOptions = e.value;
          this.control.patchValue(''); // triggers the valueChanges below which populates filteredOptions
        }
      });
      // filter options based on search
      this.filteredOptions = this.control.valueChanges.pipe(
        startWith(''),
        map((search) => {
          if (typeof search === 'string') {
            let lowerSearch = search.toLowerCase();
            return this.jsonOptions.filter(
              (option) =>
                option.label.toLowerCase().includes(lowerSearch) ||
                option.value.toLowerCase().includes(lowerSearch)
            );
          }
        })
      );
    } else {
      if (
        this.props.hasLocations === undefined ||
        this.props.hasLocations === null
      ) {
        (this.props.options as Observable<FormlyDropdownValue[]>).subscribe(
          (options) => {
            this.results = options; 
          }
        );
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }
}
