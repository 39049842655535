import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyHookFn } from '@ngx-formly/core/lib/models/fieldconfig';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { getChildObject, getChildObjects, getFormRoot } from './form-data.utility';
import { Observable, combineLatest, forkJoin } from 'rxjs';

export const calcWithConstant = (
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const p = field.parent;
    const sourceFieldId = field.props['sourceField'];
    const sourceField = getChildObject(p, sourceFieldId);
    const constant = field['constant'] as number;
    const operator = field['op'] as string;
    const precision = 10000;
    sourceField.formControl.valueChanges.pipe(
      takeUntil(destroyed$),
      startWith(sourceField.defaultValue),
      filter(v => v !== null && v !== undefined),
    ).subscribe(
      {
        'next': (v) => {
          var res = null;
          if(operator === '*'){
            res = v * constant;
          } else if(
            operator === '+'
          ){
            res = v + constant;
          } else if(
            operator === '-'
          ){
            res = v - constant;
          } else if(
            operator === '/'
          ){
            if(constant !== 0){
              res = v / constant;
            }
          }

          if(res !== null && res !== undefined){
            res = Math.round(res*precision)/precision;
            field.formControl.setValue(res);
          }

        },
        'error': (err) => console.log(err)
      }
    )
  }

}

export const calcMin = (
  destroyed$
): FormlyHookFn => {
  return (field: FormlyFieldConfig) => {
    const root = getFormRoot(field);
    const fields = getChildObjects(root, 'minField') as FormlyFieldConfig[];

    const valueChangesArray: Observable<any>[] = [];
    fields.forEach(f => valueChangesArray.push(f.formControl.valueChanges.pipe(
      startWith((f.formControl.value !== null && f.formControl.value !== undefined) ? f.formControl.value : 0)
    )
    ));

    combineLatest(valueChangesArray)
    .pipe(
      takeUntil(destroyed$)
    ).subscribe(
      {
        'complete': () => {},
        'next': (arr) => {
          var min = null;
          arr.forEach(
            v => {
              if(v !== null){
                if(min === null){
                  min = v;
                } else if(v < min) {
                  min = v;
                }
              }
            }
          );
          if(min !== null){
            field.formControl.patchValue(min);
          }
        },
        'error': (err) => {
          console.log(err);
        },
      }
    )
  };
};

