import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
  } from '@angular/core';
  import { IntellioFormlyWrapperComponent } from './itc-formly-wrapper.component';
  import { WrapperChangeDetectNotifierService } from './wrapper-change-detect-notifier.service';
import { FeatureManagementService } from '@intellio/shared/services';

  @Component({
    selector: 'itc-feature-flag',
    template: `
      <div [hidden]="!shouldRender">
        <ng-template #fieldComponent></ng-template>
      </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class FeatureFlagComponent
    extends IntellioFormlyWrapperComponent
    implements OnInit, OnDestroy
  {
    shouldRender = null;
    // defaulting to hide the field/section when the feature flag is OFF.
    // Users can override this to true via props if they want
    // to hide a field/section when a feature flag is ON.
    hideOn = false;

    constructor(
      protected notifierService: WrapperChangeDetectNotifierService,
      protected cd: ChangeDetectorRef,
      protected featureService: FeatureManagementService
    ) {
      super(notifierService, cd);
    }

    ngOnInit(): void {
      this.initChangeDetectSub();

      let featureFlag: string = this.field.props.featureFlag;
      if(this.field.props.hideOn !== null &&
        this.field.props.hideOn !== undefined
      ){
        this.hideOn = this.field.props.hideOn;
      }
      this.shouldRender = this.featureService.isFeatureActivated(featureFlag) !== this.hideOn;
    }

    ngOnDestroy() {
      this.destroyed$.next(null);
      this.destroyed$.complete();
    }
  }
