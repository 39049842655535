<div class="modal">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Add Assignee
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon> cancel </mat-icon>
      </button>
    </h2>
    <div class="in-place-spinner-wrapper" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
      <form class="main-form" [formGroup]="formGroup">
        <div>
          <div>
            <mat-label i18n> <strong>User Assignees</strong></mat-label>
          </div>
          <ng-select
            [items]="users"
            appendTo="body"
            bindLabel="fullName"
            bindValue="id"
            placeholder="Select Users"
            [clearable]="false"
            [multiple]="true"
            groupBy="category"
            formControlName="userControl"
            [tabindex]="0"
            (search)="searchUsers($event.term)"
          >
          </ng-select>
        </div>
        <div>
          <div>
            <mat-label i18n> <strong>Group Assignees</strong></mat-label>
          </div>
          <ng-select
            [items]="userGroups"
            appendTo="body"
            bindLabel="name"
            bindValue="id"
            placeholder="Select User Groups"
            [clearable]="false"
            [multiple]="true"
            groupBy="category"
            formControlName="groupControl"
            [tabindex]="0"
          >
          </ng-select>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-button i18n class="secondary-button" (click)="closeDialog()">
        Cancel
      </button>
      <button mat-button i18n class="primary-button" (click)="addAssignee()">
        Submit
      </button>
    </mat-dialog-actions>
  </div>
</div>
