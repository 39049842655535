import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorrectTypeComponent } from './correct-type/correct-type.component';
import { GeocodeModalComponent } from './geocode-modal/geocode-modal.component';
import { SharedMaterialModule } from '@intellio/shared/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImportPresetModalComponent } from './import-preset-modal/import-preset-modal.component';
import { PaymentSlipModalComponent } from './payment-slip-modal/payment-slip-modal.component';
import { AppTaskDetailsComponent } from './app-task-details/app-task-details.component';
import { AddTaskAssignmentComponent } from './add-task-assignment/add-task-assignment.component';
import { AppTaskDeleteComponent } from './app-task-delete/app-task-delete.component';
import { TaskFileDeleteComponent } from './task-file-delete/task-file-delete.component';
import { AppTasksFilterComponent } from './app-tasks-filter/app-tasks-filter.component';
import { TaskFilesUploaderComponent } from './task-files-uploader/task-files-uploader.component';
import { AppTasksTemplateComponent } from './app-tasks-template/app-tasks-template.component';
import { SystemLossCalculatorComponent } from './system-loss-calculator/system-loss-calculator.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    CorrectTypeComponent,
    GeocodeModalComponent,
    ImportPresetModalComponent,
    PaymentSlipModalComponent,
    AppTaskDetailsComponent,
    AddTaskAssignmentComponent,
    AppTaskDeleteComponent,
    TaskFileDeleteComponent,
    AppTasksFilterComponent,
    AppTasksTemplateComponent,
    TaskFilesUploaderComponent,
    SystemLossCalculatorComponent,
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  exports: [
    CorrectTypeComponent,
    AppTaskDetailsComponent,
    AddTaskAssignmentComponent,
    AppTaskDeleteComponent,
    AppTasksFilterComponent,
    SystemLossCalculatorComponent,
  ],
})
export class SharedContainerComponentsModule {}

export { CorrectTypeComponent };
export { GeocodeModalComponent };
export { ImportPresetModalComponent };
export { PaymentSlipModalComponent };
export { AppTaskDetailsComponent };
export { AddTaskAssignmentComponent };
export { AppTaskDeleteComponent };
export { AppTasksFilterComponent };
export { SystemLossCalculatorComponent };
