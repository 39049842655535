import { createAction, props, union } from '@ngrx/store';
import {
  Application,
  ApplicationCreationResult,
  ApplicationStatuses,
  ApplicationTaskConfiguration,
  ApplicationType,
  BusinessRole,
  EditApplicationSync,
  RuleAlerts,
  UDF,
  UDRDetail,
  User,
} from '@intellio/shared/models';

export enum ApplicationActionTypes {
  GetApplication = '[Application] Get',
  GetApplicationSuccess = '[Application] Get Succeeded',
  GetApplicationFailure = '[Application] Get Failure',
  GetApplicationUDRs = '[Application - UDRs] Get',
  GetApplicationUDRsSucceeded = '[Application - UDRs] Get Succeeded',
  GetApplicationUDRSFailure = '[Application - UDRs] Get Failure',
  UpdateApplicationUDRs = '[Application - UDRs] Update',
  UpdateApplicationUDRsSucceeded = '[Application - UDRs] Update Success',
  UpdateApplicationUDRsFailed = '[Application - UDRs] Update Failure',
  UpdateApplicationFileRepository = '[Application - File Repository] Update',
  UpdateApplicationFileRepositorySucceeded = '[Application - File Repository] Update Success',
  UpdateApplicationFileRepositoryFailed = '[Application - File Repository] Update Failure',
  GetApplicationUDFs = '[Application - UDFs] Get',
  GetApplicationUDFsSucceeded = '[Application - UDFs] Get Succeeded',
  GetApplicationUDFsFailure = '[Application - UDFs] Get Failure',
  UpdateApplicationUDFs = '[Application - UDFs] Update',
  UpdateApplicationUDFsSucceeded = '[Application - UDFs] Update Succeeded',
  UpdateApplicationUDFsFailure = '[Application - UDFs] Update Failure',
  UpdateApplicationDetails = '[Application - Details] Update',
  UpdateApplicationDetailsSucceeded = '[Application - Details] Update Succeeded',
  UpdateApplicationDetailsFailure = '[Application - Details] Update Failure',
  UpdateApplicationType = '[Application - Type] Update',
  UpdateApplicationTypeSucceeded = '[Application - Type] Update Succeeded',
  UpdateApplicationTypeFailure = '[Application - Type] Update Failure',
  UpdateApplicationOwner = '[Application - Owner] Update',
  UpdateApplicationOwnerSucceeded = '[Application - Owner] Update Succeeded',
  UpdateApplicationOwnerFailure = '[Application - Owner] Update Failure',
  UpdateApplicationBusinessRoles = '[Application - Business Roles] Update',
  UpdateApplicationBusinessRolesSucceeded = '[Application - Business Roles] Update Succeeded',
  UpdateApplicationBusinessRolesFailure = '[Application - Business Roles] Update Failure',
  UpdateApplicationSpoc = '[Application - Spoc] Update',
  UpdateApplicationSpocSucceeded = '[Application - Spoc] Update Succeeded',
  UpdateApplicationSpocFailure = '[Application - Spoc] Update Failure',
  CreateApplication = '[Application - New] Create',
  CreateApplicationSucceeded = '[Application - New] Create Succeeded',
  CreateApplicationAssociation = '[Application - New] Create Association',
  CreateApplicationAssociationSucceeded = '[Application - New] Create Association Succeeded',
  CreateApplicationAssociationFailure = '[Application - New] Create Association Failure',
  CreateApplicationFailure = '[Application - New] Create Failure',
  UpdateApplicationArchive = '[Application - Archive] Update',
  UpdateApplicationArchiveSucceeded = '[Application - Archive] Update Succeeded',
  UpdateApplicationArchiveFailure = '[Application - Archive Update Failure',
  UpdateApplicationStatus = '[Application - Status] Update',
  UpdateApplicationStatusSucceeded = '[Application - Status] Update Succeeded',
  UpdateApplicationStatusFailure = '[Application - Status] Update Failure',
  UpdateApplicationDueDate = '[Application - Due Date] Update',
  UpdateApplicationContent = '[Application - Content] Update',
  UpdateApplicationContentSucceeded = '[Application - Content] Update Succeeded',
  GetApplicationTypes = '[ApplicationType] Get',
  GetApplicationTypesSucceeded = '[ApplicationType] Get Succeeded',
  GetApplicationTypesFailure = '[ApplicationType] Get Failure',
  GetStatuses = '[Statuses] Get',
  GetStatusesSucceeded = '[Statuses] Get Succeeded',
  GetStatusesFailure = '[Statuses] Get Failure',
  GetAllApplicationAlerts = '[AllApplicationAlerts] Get',
  GetAllApplicationAlertsSucceeded = '[AllApplicationAlerts] Get Succeeded',
  GetAllApplicationAlertsFailure = '[AllApplicationAlerts] Get Failure',
}

export const getApplication = createAction(
  ApplicationActionTypes.GetApplication,
  props<{ applicationId: string }>()
);

export const getApplicationSuccess = createAction(
  ApplicationActionTypes.GetApplicationSuccess,
  props<{ application: Application }>()
);

export const getApplicationFailure = createAction(
  ApplicationActionTypes.GetApplicationFailure,
  props<{ error: any }>()
);

export const updateApplicationUDFs = createAction(
  ApplicationActionTypes.UpdateApplicationUDFs,
  props<{ applicationId: string; udfs: UDF[] }>()
);

export const updateApplicationDetails = createAction(
  ApplicationActionTypes.UpdateApplicationDetails,
  props<{ applicationId: string; model: EditApplicationSync }>()
);

export const updateApplicationContent = createAction(
  ApplicationActionTypes.UpdateApplicationContent,
  props<{ applicationId: string; content: any }>()
);

export const getApplicationUDRs = createAction(
  ApplicationActionTypes.GetApplicationUDRs,
  props<{
    appId: string;
    udrType: string;
    hasParent: boolean;
    includeUDRs: boolean;
    detailId: number;
    fetchDepth: number;
  }>()
);

export const getApplicationUDRsSucceeded = createAction(
  ApplicationActionTypes.GetApplicationUDRsSucceeded,
  props<{ detailId: number; result: UDRDetail[] }>()
);

export const getApplicationUDRsFailure = createAction(
  ApplicationActionTypes.GetApplicationUDRSFailure,
  props<{ error: any }>()
);

export const updateApplicationUDRs = createAction(
  ApplicationActionTypes.UpdateApplicationUDRs,
  props<{ appId: string; webhookName: string }>()
);

export const updateApplicationUDRsSucceeded = createAction(
  ApplicationActionTypes.UpdateApplicationUDRsSucceeded,
  props<{ result: boolean }>()
);

export const updateApplicationUDRsFailed = createAction(
  ApplicationActionTypes.UpdateApplicationUDRsFailed,
  props<{ error: any }>()
);

export const updateApplicationFileRepository = createAction(
  ApplicationActionTypes.UpdateApplicationFileRepository,
  props<{ appId: string; udfId: string; jurisdictionId; webhookName: string }>()
);

export const updateApplicationFileRepositorySucceeded = createAction(
  ApplicationActionTypes.UpdateApplicationFileRepositorySucceeded,
  props<{ result: boolean }>()
);

export const updateApplicationFileRepositoryFailed = createAction(
  ApplicationActionTypes.UpdateApplicationFileRepositoryFailed,
  props<{ error: any }>()
);

export const updateApplicationUDFsSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationUDFsSucceeded,
  props<{ udfs: UDF[] }>()
);

export const updateApplicationUDFsFailure = createAction(
  ApplicationActionTypes.UpdateApplicationUDFsFailure,
  props<{ error: any }>()
);

export const updateApplicationDetailsSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationDetailsSucceeded,
  props<{ model: EditApplicationSync }>()
);

export const updateApplicationDetailsFailure = createAction(
  ApplicationActionTypes.UpdateApplicationDetailsFailure,
  props<{ error: any }>()
);

export const updateApplicationType = createAction(
  ApplicationActionTypes.UpdateApplicationType,
  props<{
    applicationId: string;
    typeId: string;
    typeName: string;
    detail: string;
    maxCapacity: number;
    minCapacity: number;
  }>()
);

export const updateApplicationTypeSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationTypeSucceeded,
  props<{
    typeId: string;
    typeName: string;
    detail: string;
    maxCapacity: number;
    minCapacity: number;
  }>()
);

export const updateApplicationTypeFailure = createAction(
  ApplicationActionTypes.UpdateApplicationTypeFailure,
  props<{ error: any }>()
);

export const updateApplicationOwner = createAction(
  ApplicationActionTypes.UpdateApplicationOwner,
  props<{ applicationIds: string[]; owner: User; muteNotification: boolean }>()
);

export const updateApplicationOwnerSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationOwnerSucceeded,
  props<{ owner: User }>()
);

export const updateApplicationContentSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationContentSucceeded,
  props<{ content: any }>()
);

export const createApplicationAssociationSucceeded = createAction(
  ApplicationActionTypes.CreateApplicationAssociationSucceeded,
  props<{ associatedId: any }>()
);

export const updateApplicationOwnerFailure = createAction(
  ApplicationActionTypes.UpdateApplicationOwnerFailure,
  props<{ error: any }>()
);

export const updateApplicationSpoc = createAction(
  ApplicationActionTypes.UpdateApplicationSpoc,
  props<{
    applicationIds: string[];
    assignedSpoc: User;
    muteNotification: boolean;
  }>()
);
export const updateApplicationSpocSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationSpocSucceeded,
  props<{ assignedSpoc: User }>()
);

export const updateApplicationSpocFailure = createAction(
  ApplicationActionTypes.UpdateApplicationSpocFailure,
  props<{ error: any }>()
);

export const updateApplicationBusinessRoles = createAction(
  ApplicationActionTypes.UpdateApplicationBusinessRoles,
  props<{
    applicationId: string;
    payload: any;
  }>()
);

export const updateApplicationBusinessRolesSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationBusinessRolesSucceeded,
  props<{ businessRoles: BusinessRole[] }>()
);

export const updateApplicationBusinessRolesFailure = createAction(
  ApplicationActionTypes.UpdateApplicationBusinessRolesFailure,
  props<{ error: any }>()
);

export const createApplication = createAction(
  ApplicationActionTypes.CreateApplication,
  props<{
    payload;
  }>()
);

export const createApplicationSuccess = createAction(
  ApplicationActionTypes.CreateApplicationSucceeded,
  props<{ result: ApplicationCreationResult }>()
);

export const createApplicationAssociation = createAction(
  ApplicationActionTypes.CreateApplicationAssociation,
  props<{ applicationId: string; associatedIds: any }>()
);

export const createApplicationFailure = createAction(
  ApplicationActionTypes.CreateApplicationFailure,
  props<{ error: any }>()
);

export const updateApplicationArchive = createAction(
  ApplicationActionTypes.UpdateApplicationArchive,
  props<{
    archiveStatus: boolean;
    applicationId: string;
  }>()
);

export const updateApplicationArchiveSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationArchiveSucceeded,
  props<{ archiveStatus: boolean }>()
);

export const updateApplicationArchiveFailure = createAction(
  ApplicationActionTypes.UpdateApplicationArchiveFailure,
  props<{ error: any }>()
);

export const updateApplicationStatus = createAction(
  ApplicationActionTypes.UpdateApplicationStatus,
  props<{
    applicationId: string;
    newStatusId: string;
    fileNames: string[];
    postBody: string;
    muteNotification: boolean;
    attachFilesToEmail: boolean;
    newStatusName: string;
    newOwnerId: string;
    taskConfigs?: ApplicationTaskConfiguration[]
  }>()
);

export const updateApplicationStatusSuccess = createAction(
  ApplicationActionTypes.UpdateApplicationStatusSucceeded,
  props<{ newStatusId: string; newStatusName: string }>()
);

export const updateApplicationStatusFailure = createAction(
  ApplicationActionTypes.UpdateApplicationStatusFailure,
  props<{ error: any }>()
);

export const updateApplicationDueDate = createAction(
  ApplicationActionTypes.UpdateApplicationDueDate,
  props<{
    newDueDate: string;
  }>()
);
export const all = union({
  createApplicationSuccess,
  createApplicationFailure,
});

export const getApplicationTypes = createAction(
  ApplicationActionTypes.GetApplicationTypes
);

export const getApplicationTypesSucceeded = createAction(
  ApplicationActionTypes.GetApplicationTypesSucceeded,
  props<{ applicationTypes: ApplicationType[] }>()
);

export const getApplicationTypesFailure = createAction(
  ApplicationActionTypes.GetApplicationTypesFailure,
  props<{ error: any }>()
);

export const getStatuses = createAction(ApplicationActionTypes.GetStatuses);

export const getStatusesSucceeded = createAction(
  ApplicationActionTypes.GetStatusesSucceeded,
  props<{ statuses: ApplicationStatuses[] }>()
);

export const getStatusesFailure = createAction(
  ApplicationActionTypes.GetStatusesFailure,
  props<{ error: any }>()
);

export const getAllApplicationAlerts = createAction(
  ApplicationActionTypes.GetAllApplicationAlerts
);

export const getAllApplicationAlertsSucceeded = createAction(
  ApplicationActionTypes.GetAllApplicationAlertsSucceeded,
  props<{ allApplicationAlerts: RuleAlerts[] }>()
);

export const getAllApplicationAlertsFailure = createAction(
  ApplicationActionTypes.GetAllApplicationAlertsFailure,
  props<{ error: any }>()
);

export type ApplicationActionsUnion = typeof all;
