export enum Permission {
  AdminRead = 'AdminRead',
  EngineerRead = 'EngineerRead',
  EngineerReadWrite = 'EngineerReadWrite',
  ApplicationsRead = 'ApplicationsRead',
  AssignNewApplications = 'AssignNewApplications',
  AcceptNewApplications = 'AcceptNewApplications',
  RoleRead = 'RoleRead',
  RoleReadWrite = 'RoleReadWrite',
  ApplicationDashboardRead = 'ApplicationDashboardRead',
  StatusHistoryRead = 'StatusHistoryRead',
  StatusHistoryReadWrite = 'StatusHistoryReadWrite',
  ApplicationFormRead = 'ApplicationFormRead',
  ApplicationFormWrite = 'ApplicationFormWrite',
  ApplicationFormGlobalWrite = 'ApplicationFormGlobalWrite',
  ApplicationDelete = 'ApplicationDelete',
  BulkChangeEditor = 'BulkChangeEditor',
  ApplicationArchiver = 'ApplicationArchiver',
  PaymentsRead = 'PaymentsRead',
  PaymentCreate = 'PaymentCreate',
  PaymentEdit = 'PaymentEdit',
  PaymentDelete = 'PaymentDelete',
  ManageCollaborators = 'ManageCollaborators',
  ApplicationCreator = 'ApplicationCreator',
  TimersRead = 'TimersRead',
  TimersExtensionRead = 'TimersExtensionRead',
  FormsRead = 'FormsRead',
  ApplicationHistoryRead = 'ApplicationHistoryRead',
  FilesRead = 'FilesRead',
  FilesEdit = 'FilesEdit',
  MessagesRead = 'MessagesRead',
  MessageCenterRead = 'MessageCenterRead',
  UDFsRead = 'UDFsRead',
  UDFsEdit = 'UDFsEdit',
  UDRsRead = 'UDRsRead',
  ApplicationTypeEdit = 'ApplicationTypeEdit',
  CollaboratorsRead = 'CollaboratorsRead',
  FormDebugLogsRead = 'FormDebugLogsRead',
  CanAssignSelfAdmin = 'CanAssignSelfAdmin',
  CanAssignSelfSuperAdmin = 'CanAssignSelfSuperAdmin',
  CanAssignElevatedPrivilegeRoles = 'CanAssignElevatedPrivilegeRoles',
  SSOReadWrite = 'SSOReadWrite',
  TemplatesRead = 'TemplatesRead',
  TemplatesCreate = 'TemplatesCreate',
  TemplatesEdit = 'TemplatesEdit',
  TasksRead = 'TasksRead',
  TasksWrite = 'TasksWrite',
  ApplicationFormOverride = 'ApplicationFormOverride',
  ExtensionsEdit = 'ExtensionsEdit',
  WorkManager = 'WorkManager',
  SendFileToDocuSign = 'SendFileToDocuSign',
  AppSummaryEdit = 'AppSummaryEdit',
  StatusTimerFlyoutRead = 'StatusTimerFlyoutRead',
  GetRecommendedStatuses = 'GetRecommendedStatuses',
  ViewAppMenu = 'ViewAppMenu',
  HelpEdit = 'HelpEdit',
  TimerAlertEdit = 'TimerAlertEdit',
  TimerAlertEditPreference = 'TimerAlertEditPreference',
  ApplicationRelationCreate = 'ApplicationRelationCreate',
  ApplicationRelationDelete = 'ApplicationRelationDelete',
  ApplicationRelationView = 'ApplicationRelationView',
  MakeOnlinePayment = 'MakeOnlinePayment',
}
