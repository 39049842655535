import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedMaterialModule } from '@intellio/shared/material';
import { LeaveBeforeSavingComponent } from './components/leave-before-saving/leave-before-saving.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { AutoFloatLabelDirective } from './formly-components/auto-float-label.directive';
import { FileTypeComponent } from './formly-components/file-type.component';
import { FileValueAccessorDirective } from './formly-components/file-value-accessor.directive';
import { RepeatSectionTypeComponent } from './formly-components/repeat-section-type.component';
import { FormlyModule } from '@ngx-formly/core';
import { SectionWrapperComponent } from './formly-components/section-wrapper.component';
import { TitleComponent } from './formly-components/title.component';
import { SectionComponent } from './formly-components/section.component';
import { FormlyAccordionComponent } from './formly-components/formly-accordion.component';
import { AddonsWrapperComponent } from './formly-components/addons-wrapper.component';
import { addonsExtension } from './formly-components/addons.extension';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { MatTreeModule } from '@angular/material/tree';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldAggregatorComponent } from './formly-components/field-aggregator.component';
import { PermissionsRequiredDirective } from './directives/permissions-required.directive';
import { IconRegistry } from './components/icon/icon-registry-list';
import { FormErrorMessagePipe } from './pipes/form-error-message.pipe';
import { FormlyDebugComponent } from './formly-components/formly-debug.component';
import { ModuleEnabledDirective } from './directives/module-enabled.directive';
import { FeatureEnabledDirective } from './directives/feature-enabled.directive';
import { PrecisionDecimalPipe } from './pipes/precision-decimal.pipe';
import { FormatSecondsAsDaysPipe } from './pipes/format-seconds-as-days.pipe';
import { ConditionalHideComponent } from './formly-components/conditional-hide.wrapper.component';
import { AuthorizeComponent } from './formly-components/authorize.wrapper.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
// bug 66468 all imports
import {
  // eslint-disable-line
  phoneNumberValidator,
  zipCodeValidator,
  emailValidator,
  feinValidator,
  requireTrueValidator,
  accountNumberValidator,
  repeatValidator,
} from '@intellio/shared/services';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import { FormlyMatFormFieldModule } from '@ngx-formly/material/form-field';
import { AnalyticsEventDirective } from './directives/analytics-event.directive';
import { ButtonTypeComponent } from './formly-components/button.component';
import { HelpTypeComponent } from './formly-components/help.component';
import { FormlyReadonlyComponent } from './formly-components/formly-readonly/formly-readonly.component';
import { ContextualHelpModalComponent } from './formly-components/contextual-help-modal/contextual-help-modal.component';
import { TypeAheadTypeComponent } from './formly-components/type-ahead.component';
import { ApplicationTypeTypeComponent } from './formly-components/application.type.component';
import { RadioTypeComponent } from './formly-components/radio.component';
import { StickyDirective } from './directives/sticky.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AriaPipe } from './pipes/get-aria-label.pipe';
import { InputComponent } from './formly-components/input.component';
import { TimepickerComponent } from './formly-components/formly-timepicker.component';
import { LineBreaksPipe } from './pipes/preserve-line-breaks.pipe';
import { FillEmptyValuePipe } from './pipes/fill-empty-value.pipe';
import { VarDirective } from './directives/itc-var.directive';
import { TextAreaComponent } from './formly-components/text-area.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { RichTextComponent } from './formly-components/rich-text.component';
import { LegacyReadFieldComponent } from './formly-components/legacy-read-field.component';
import { TitleDropdownNavComponent } from './components/title-dropdown-nav/title-dropdown-nav.component';
import { RouterModule, Route } from '@angular/router';
import { SharedTranslateModule } from '@intellio/shared/translate'; // eslint-disable-line

import { NgSelectModule } from '@ng-select/ng-select';
import { StepperComponent } from './formly-components/stepper.component';
import { AppCardListComponent } from './formly-components/app-card-list.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FieldMaskDirective } from './formly-components/field-mask.directive';
import { CheckboxComponent } from './formly-components/checkbox.component';
import { cypressExtension } from './formly-components/cypress.extension';
import { CypressWrapperComponent } from './formly-components/cypress.wrapper.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { IsOnTeamPipe } from './pipes/is-on-team.pipe';
import { IsMessageCurrentTeamPipe } from './pipes/is-message-current-team.pipe';
import { FeatureFlagComponent } from './formly-components/feature-flag.wrapper.component';

export function minLengthValidationMessage(err, field) {
  return `This value should be ${field.props.minLength} characters ${
    field.props.maxLength == field.props.minLength ? '' : ' or more'
  }`;
}

export function minimumValidationMessage(err, field) {
  return `This value should be at least ${field.props.min}`;
}

export function maxLengthValidationMessage(err, field) {
  return `This value should be ${field.props.maxLength} characters or less`;
}

export function invalidEmailMessage(err) {
  return `Input is not a valid email - check format and remove extra spaces`;
}

export function invalidRepeatMessage(err, field) {
  return `You need to add at least 1 ${field.props.itemName}`;
}

@NgModule({
  declarations: [
    AppCardListComponent,
    LeaveBeforeSavingComponent,
    AutoFloatLabelDirective,
    FileTypeComponent,
    FileValueAccessorDirective,
    RepeatSectionTypeComponent,
    SectionWrapperComponent,
    FeatureFlagComponent,
    TitleComponent,
    SectionComponent,
    AddonsWrapperComponent,
    FieldAggregatorComponent,
    PermissionsRequiredDirective,
    FormErrorMessagePipe,
    FormlyDebugComponent,
    ConditionalHideComponent,
    AuthorizeComponent,
    ModuleEnabledDirective,
    FeatureEnabledDirective,
    PrecisionDecimalPipe,
    FormatSecondsAsDaysPipe,
    AnalyticsEventDirective,
    FormlyReadonlyComponent,
    ButtonTypeComponent,
    HelpTypeComponent,
    ContextualHelpModalComponent,
    TypeAheadTypeComponent,
    ApplicationTypeTypeComponent,
    RadioTypeComponent,
    FormlyAccordionComponent,
    StickyDirective,
    SafeHtmlPipe,
    FilterPipe,
    InputComponent,
    TimepickerComponent,
    LineBreaksPipe,
    FillEmptyValuePipe,
    VarDirective,
    TextAreaComponent,
    RichTextComponent,
    TruncatePipe,
    LegacyReadFieldComponent,
    TitleDropdownNavComponent,
    StepperComponent,
    FieldMaskDirective,
    CheckboxComponent,
    FormErrorsComponent,
    AriaPipe,
    IsOnTeamPipe,
    IsMessageCurrentTeamPipe,
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgSelectModule,
    SharedMaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    SharedTranslateModule,
    FormlyModule.forChild({
      types: [
        { name: 'repeat', component: RepeatSectionTypeComponent },
        {
          name: 'file',
          component: FileTypeComponent,
        },
        {
          name: 'field-aggregator',
          component: FieldAggregatorComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'help',
          component: HelpTypeComponent,
        },
        {
          name: 'button',
          component: ButtonTypeComponent,
        },
        {
          name: 'app-card-list',
          component: AppCardListComponent,
        },
        {
          name: 'input',
          component: InputComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'timepicker',
          component: TimepickerComponent,
        },
        {
          name: 'radio',
          component: RadioTypeComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'checkbox',
          component: CheckboxComponent,
        },
        { name: 'stepper', component: StepperComponent, wrappers: [] },
        { name: 'typeahead', component: TypeAheadTypeComponent },
        { name: 'application-type', component: ApplicationTypeTypeComponent },
        { name: 'text-area', component: TextAreaComponent },
        { name: 'rich-text', component: RichTextComponent },
        { name: 'legacy-read-only', component: LegacyReadFieldComponent },
      ],
      wrappers: [
        { name: 'section-wrapper', component: SectionWrapperComponent },
        { name: 'section', component: SectionComponent },
        { name: 'addons', component: AddonsWrapperComponent },
        { name: 'conditional', component: ConditionalHideComponent },
        { name: 'authorize', component: AuthorizeComponent },
        { name: 'accordion', component: FormlyAccordionComponent },
        { name: 'title', component: TitleComponent },
        { name: 'cypress', component: CypressWrapperComponent },
        { name: 'feature-flag', component: FeatureFlagComponent }
      ],
      extensions: [
        { name: 'addons', extension: { onPopulate: addonsExtension } },
        { name: 'cypress', extension: { onPopulate: cypressExtension } },
      ],
      validators: [
        {
          name: 'phone',
          validation: phoneNumberValidator,
        },
        {
          name: 'zip',
          validation: zipCodeValidator,
        },
        {
          name: 'email',
          validation: emailValidator,
        },
        {
          name: 'fein',
          validation: feinValidator,
        },
        {
          name: 'require-true',
          validation: requireTrueValidator,
        },
        {
          name: 'account-number',
          validation: accountNumberValidator,
        },
        {
          name: 'repeat',
          validation: repeatValidator,
        },
      ],
      validationMessages: [
        { name: 'minLength', message: minLengthValidationMessage },
        { name: 'min', message: minimumValidationMessage },
        { name: 'maxLength', message: maxLengthValidationMessage },
        { name: 'invalidEmail', message: invalidEmailMessage },
        { name: 'invalidRepeat', message: invalidRepeatMessage },
      ],
    }),
    FormlyMaterialModule,
    FormlyMatFormFieldModule,
    FormlyMatDatepickerModule,
    MatTreeModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    ScrollingModule
  ],
  exports: [
    FormlyModule,
    FormlyMaterialModule,
    FormlyMatFormFieldModule,
    FormlyMatDatepickerModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    PermissionsRequiredDirective,
    FormErrorMessagePipe,
    FormlyDebugComponent,
    ConditionalHideComponent,
    AuthorizeComponent,
    ModuleEnabledDirective,
    FeatureEnabledDirective,
    PrecisionDecimalPipe,
    FormatSecondsAsDaysPipe,
    AnalyticsEventDirective,
    FormlyReadonlyComponent,
    StickyDirective,
    SafeHtmlPipe,
    FilterPipe,
    LineBreaksPipe,
    InputComponent,
    TimepickerComponent,
    FillEmptyValuePipe,
    VarDirective,
    TextAreaComponent,
    RichTextComponent,
    TruncatePipe,
    LegacyReadFieldComponent,
    TitleDropdownNavComponent,
    FieldMaskDirective,
    CheckboxComponent,
    FormErrorsComponent,
    AriaPipe,
    IsOnTeamPipe,
    IsMessageCurrentTeamPipe,
  ],
  providers: [provideNgxMask()],
})
export class SharedComponentsModule {}

export { LeaveBeforeSavingComponent };
export * from './components/leave-before-saving/leave-before-saving.guard';
export { AutoFloatLabelDirective };
export { FileTypeComponent };
export { FormlyDebugComponent };
export { FileValueAccessorDirective };
export { RepeatSectionTypeComponent };
export { IconRegistry };
export { FormErrorMessagePipe };
export { SafeHtmlPipe };
export { LineBreaksPipe };
export { AriaPipe };
export { PermissionsRequiredDirective };
export { MatTreeModule };
export { PrecisionDecimalPipe };
export { FormatSecondsAsDaysPipe };
export { ConditionalHideComponent };
export { AuthorizeComponent };
export { AnalyticsEventDirective };
export { FormlyReadonlyComponent };
export { InputComponent };
export { TimepickerComponent };
export { LegacyReadFieldComponent };
export { FillEmptyValuePipe };
export { TitleDropdownNavComponent };
export { CheckboxComponent };
export { FormErrorsComponent };
export { ModuleEnabledDirective };
export { FeatureEnabledDirective };
export { IsOnTeamPipe };
export { IsMessageCurrentTeamPipe };
