import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  IntellioHttpResponse,
  RefreshResponse,
  Token,
} from '@intellio/shared/models';
import { processData } from '@intellio/shared/utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeWhile, tap } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { LocalStorageService } from './local-storage.service';
import { ModuleService } from './module.service';
import { NotificationService } from './notification.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  /* Good candidate for auth:
    https://github.com/manfredsteyer/angular-oauth2-oidc
    */

  private authTokenSubject$ = new BehaviorSubject<Token>(null);
  private tenantLoginMessages: string;
  private tenantLoginAlerts: string;
  private showLoginPageBanner: boolean;
  private showLoginPageBannerText: boolean;
  private _authToken: Token;
  private _accessToken: string;
  private _accessTokenType: string;

  constructor(
    private client: HttpClient,
    private appConfigService: AppConfigService,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private moduleService: ModuleService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  get authToken$() {
    return this.authTokenSubject$.asObservable();
  }

  get tokenKey(): string {
    return 'token';
  }

  get accessTokenKey(): string {
    return 'accessToken';
  }

  get accessTokenTypeKey(): string {
    return 'accessTokenType';
  }

  get refreshTokenKey(): string {
    return 'refreshToken';
  }

  get authToken(): Token {
    const prevToken = this._authToken;
    if (this.appConfigService.tenantConfig == null) {
      this._authToken = null;
    } else {
      if (this._authToken == null) {
        if (this.appConfigService.tenantConfig.enableAutomaticLogin) {
          this._authToken = this.localStorageService.get(this.tokenKey);
        } else {
          this._authToken = this.sessionStorageService.get(this.tokenKey);
        }
      }
    }

    if (prevToken !== this._authToken) {
      this.authTokenSubject$.next(this._authToken);
    }
    return this._authToken;
  }

  get accessToken(): string {
    if (this._accessToken == null || this._accessToken === '') {
      this._accessToken = this.sessionStorageService.get(this.accessTokenKey);
    }

    return this._accessToken;
  }

  get accessTokenType(): string {
    if (this._accessTokenType == null || this._accessTokenType === '') {
      this._accessTokenType = this.sessionStorageService.get(
        this.accessTokenTypeKey
      );
    }

    return this._accessTokenType;
  }

  ensureAccessTokenIsValid(): Observable<boolean> {
    // If on an external page, ignore this validation
    if (this.authToken == null) {
      return of(true);
    }

    const tokenExpiration = this.authToken['.expires'];

    if (new Date(tokenExpiration) < new Date()) {
      return this.renewToken();
    }

    return of(true);
  }

  loginUserWithAuthCode(ssoLoginData) {
    const loginUserUrl = '/token';
    const userCredentials: Record<string, unknown> = {};
    userCredentials.grant_type = 'authorization_code';
    userCredentials.code = ssoLoginData.code;
    userCredentials.client_id = decodeURIComponent(ssoLoginData.client_id);

    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    return this.client
      .post<Token>(
        this.appConfigService.url + loginUserUrl,
        processData(options, userCredentials),
        options
      )
      .pipe(
        tap((value) => {
          this.sessionStorageService.set(
            this.accessTokenKey,
            value.access_token
          );
          this.sessionStorageService.set(
            this.accessTokenTypeKey,
            value.token_type
          );
          this.sessionStorageService.set(
            this.refreshTokenKey,
            value.refresh_token
          );

          this._authToken = value;
          this._accessToken = value.access_token;
          this._accessTokenType = value.token_type;

          if (this.appConfigService.tenantConfig.enableAutomaticLogin) {
            this.localStorageService.set(this.tokenKey, value);
          } else {
            this.sessionStorageService.set(this.tokenKey, value);
          }

          this.authTokenSubject$.next(value);
        })
      );
  }

  loginUser(
    username: string,
    password: string,
    recaptchaResponse?: string
  ): Observable<Token> {
    const loginUrl = '/token';
    const data: Record<string, unknown> = {
      grant_type: 'password',
      username,
      password,
    };
    if (recaptchaResponse) {
      data.recaptchaResponse = recaptchaResponse;
    }

    const options = {
      headers: {
        'X-Allow-Anonymous': 'true',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    // Note: we are intentionally not using the BaseService's post method
    // since the token endpoint returns a Token object and not a IntellioHttpResponse<Token> object
    return this.client
      .post<Token>(
        this.appConfigService.url + loginUrl,
        processData(options, data),
        options
      )
      .pipe(
        tap((value) => {
          this.sessionStorageService.set(
            this.accessTokenKey,
            value.access_token
          );
          this.sessionStorageService.set(
            this.accessTokenTypeKey,
            value.token_type
          );
          this.sessionStorageService.set(
            this.refreshTokenKey,
            value.refresh_token
          );

          this._authToken = value;
          this._accessToken = value.access_token;
          this._accessTokenType = value.token_type;

          if (this.appConfigService.tenantConfig.enableAutomaticLogin) {
            this.localStorageService.set(this.tokenKey, value);
          } else {
            this.sessionStorageService.set(this.tokenKey, value);
          }

          this.authTokenSubject$.next(value);
        })
      );
  }

  logoutUser() {
    this._authToken = null;
    this._accessToken = '';
    this._accessTokenType = '';
    this.localStorageService.remove(this.tokenKey);
    this.localStorageService.set('previousSearch', "");
    this.sessionStorageService.remove(this.tokenKey);
    this.sessionStorageService.set(this.accessTokenKey, '');
    this.sessionStorageService.set(this.accessTokenTypeKey, '');
    this.sessionStorageService.set(this.refreshTokenKey, '');
    this.authTokenSubject$.next(null);
    this.notificationService.dismiss();
  }

  isUserLoggedIn(): Observable<string> {
    if (this.appConfigService.tenantConfig.enableAutomaticLogin) {
      if (this.localStorageService.doesExist(this.tokenKey)) {
        if (this.moduleService.isModuleEnabled('SingleSignOn')) {
          this.logoutUser();
          return of('');
        }

        this.sessionStorageService.set(
          this.accessTokenKey,
          this.authToken.access_token
        );
        this.sessionStorageService.set(
          this.accessTokenTypeKey,
          this.authToken.token_type
        );
        this.sessionStorageService.set(
          this.refreshTokenKey,
          this.authToken.refresh_token
        );

        this._accessToken = this.authToken.access_token;
        this._accessTokenType = this.authToken.token_type;

        return this.getNewSession().pipe(
          map((res) => {
            this.sessionStorageService.set(this.accessTokenKey, res.data);
            this._accessToken = res.data;

            return res.data;
          })
        );
      }
    } else {
      if (this.sessionStorageService.doesExist(this.tokenKey)) {
        this._accessToken = this.authToken.access_token;
        this._accessTokenType = this.authToken.token_type;

        return of(this._accessToken);
      }
    }

    // Clear login info if not auto-login
    this.logoutUser();
    return of('');
  }

  redirectToReturnUrl(returnUrl: string = '') {
    if (returnUrl !== '') {
      this.router.navigateByUrl(returnUrl);
    } else {
      this.router.navigateByUrl('/applications');
    }
  }

  private getNewSession(): Observable<IntellioHttpResponse<string>> {
    const serviceUrl = '/api/account/newSession';

    return this.ensureAccessTokenIsValid().pipe(
      switchMap(() => {
        return this.client.get<IntellioHttpResponse<string>>(
          this.appConfigService.url + serviceUrl,
          {}
        );
      })
    );
  }

  renewToken(): Observable<boolean> {
    const refreshToken = sessionStorage.getItem(this.refreshTokenKey);

    if (!refreshToken) {
      this.logoutUser();
      this.router.navigateByUrl('/login');
      return of(false);
    }

    const body = `grant_type=refresh_token&refresh_token=${refreshToken}`;

    return this.client
      .post<Token>(`${this.appConfigService.url}/token`, body, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .pipe(
        map((response) => {
          this.sessionStorageService.set(
            this.accessTokenKey,
            response.access_token
          );
          this.sessionStorageService.set(
            this.accessTokenTypeKey,
            response.token_type
          );
          this.sessionStorageService.set(
            this.refreshTokenKey,
            response.refresh_token
          );

          this._authToken = response;
          this._accessToken = response.access_token;
          this._accessTokenType = response.token_type;

          if (this.appConfigService.tenantConfig.enableAutomaticLogin) {
            this.localStorageService.set(this.tokenKey, response);
          } else {
            this.sessionStorageService.set(this.tokenKey, response);
          }
          return true;
        }),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            // Token renewal happens before the refresh token expires
            // So we could notify the user they will be logged out or give the option to retry renewal
            this.logoutUser();
            this.router.navigateByUrl('/login');
          }

          return of(false);
        }),
        // Cancel the observable if the token was not renewed
        takeWhile((tokenRenewed) => tokenRenewed)
      );
  }
}
