import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, throwError } from 'rxjs';
import {
  ApplicationFile,
  ApplicationFolder,
  DocusignFile,
  FileTemplate,
  FileTemplateVersion,
  FileType,
  ApplicationTypeStatusFileType,
  FileUploadWithMetadata,
} from '@intellio/shared/models';
import { take } from 'rxjs/operators';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { WebhookService } from './webhook.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFilesService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService,
    private webhookService: WebhookService
  ) {
    super(client, configService, authService);
  }

  isHideDocuSignReviewCheckbox: boolean;

  getFiles(appId: string) {
    const filesUrl = `/api/applications/${appId}/files`;
    return this.get<ApplicationFile[]>(filesUrl, {});
  }

  getFolders(appId: string) {
    const foldersUrl = `/api/applications/${appId}/folders`;
    return this.get<ApplicationFolder[]>(foldersUrl, {});
  }

  downloadFile(appId: string, fileName: string): void {
    const url = `/api/applications/${appId}/file/download`;

    let params = new HttpParams();
    params = params.append('fileName', fileName);
    this.getFile(url, {
      params,
    })
      .pipe(take(1))
      .subscribe();
  }

  downloadTaskFile(fileName: string, taskId: string): void {
    const url = `/api/tasks/${taskId}/file/download`;

    let params = new HttpParams();
    params = params.append('fileName', fileName);
    if (taskId !== null) {
      params = params.append('taskId', taskId);
    }
    this.getFile(url, {
      params,
    })
      .pipe()
      .subscribe();
  }

  deleteFile(
    appId: string,
    fileName: string,
    requireEditableAppForm?: boolean
  ) {
    let fileUrl = `/api/applications/${appId}/file`;
    let params = new HttpParams();
    params = params.append('fileName', fileName);
    if (requireEditableAppForm !== null) {
      params = params.append('requireEditableAppForm', requireEditableAppForm);
    }

    return this.delete<string>(fileUrl, {
      params,
    });
  }

  deleteFolder(appId: string, data: ApplicationFolder) {
    const url = `/api/applications/${appId}/folders`;
    let params = new HttpParams();
    params = params.append('folderName', data.folderName);
    params = params.append('folderId', data.folderId);

    return this.delete(url, { params });
  }

  deleteTaskFile(
    taskId: string,
    fileName: string,
    requireEditableAppForm?: boolean
  ) {
    let fileUrl = `/api/tasks/${taskId}/file`;
    let params = new HttpParams();
    params = params.append('fileName', fileName);
    if (requireEditableAppForm !== null) {
      params = params.append('requireEditableAppForm', requireEditableAppForm);
    }
    if (taskId !== null) {
      params = params.append('taskId', taskId);
    }

    return this.delete<string>(fileUrl, {
      params,
    });
  }

  getFileTypes() {
    const fileTypesUrl = '/api/applications/filetypes';
    return this.get<FileType[]>(fileTypesUrl);
  }

  getAppTypeStatusFileTypes(){
    const appTypeStatusFileTypeUrl = '/api/applications/types/statuses/typestatusfiletypes'
    return this.get<ApplicationTypeStatusFileType[]>(appTypeStatusFileTypeUrl);
  }

  getFileTypesByAppType(appTypeId: string) {
    const fileTypesUrl = `/api/applications/types/${appTypeId}/filetypes`;
    return this.get<FileType[]>(fileTypesUrl);
  }

  uploadFile(appId: string, data: FileUploadWithMetadata) {
    const fileUploadUrl = `/api/applications/${appId}/files`;
    let params = new HttpParams();

    Object.keys(data).forEach((val) => {
      if (val !== 'file') {
        params = params.append(val, data[val]);
      }
    });
    const options = {
      params,
    };

    return this.uploadFiles(fileUploadUrl, [data], options);
  }

  uploadTaskFile(taskId: string, data: FileUploadWithMetadata) {
    const fileUploadUrl = `/api/tasks/${taskId}/file`;
    let params = new HttpParams();

    Object.keys(data).forEach((val) => {
      if (val !== 'file') {
        params = params.append(val, data[val]);
      }
    });
    if (taskId != null && taskId != undefined) {
      params = params.append('taskId', taskId);
    }
    const options = {
      params,
    };

    return this.uploadFiles(fileUploadUrl, [data], options);
  }

  uploadFolder(appId: string, data: ApplicationFolder) {
    const url = `/api/applications/${appId}/folders`;
    return this.post(url, data);
  }

  updateFile(appId: string, updatedFile: ApplicationFile) {
    const updateFileUrl = `/api/applications/${appId}/file`;
    return this.put<string>(updateFileUrl, updatedFile);
  }

  saveFileToFolder(appId: string, data: any) {
    const url = `/api/applications/${appId}/folders/file`;
    return this.post(url, data);
  }

  sendFileForEsignature(payload) {
    const url = '/api/docusignEsignature';
    return this.post(url, payload);
  }

  getFileTemplates() {
    const getFileTemplatesUrl = `/api/documenttemplate`;
    return this.get<FileTemplate[]>(getFileTemplatesUrl);
  }

  getFileTemplateVersions(templateId) {
    const getFileTemplateVersionsUrl = `/api/documenttemplate/${templateId}/versions`;
    return this.get<FileTemplateVersion[]>(getFileTemplateVersionsUrl);
  }

  getFileTemplatesByTypeStatusJurisdiction(
    applicationType,
    applicationStatus,
    applicationJurisdiction
  ) {
    const url = `/api/documenttemplate/${applicationType}/${applicationStatus}/${applicationJurisdiction}`;
    return this.get<FileTemplate[]>(url);
  }

  interpolateFileTemplate(dataToPost, isPdf) {
    const interpolateTemplateUrl = `/api/documenttemplate/interpolate?saveAsPdf=${isPdf}`;
    return this.post<string>(interpolateTemplateUrl, dataToPost);
  }

  interpolateCustomFile(appId, fileTypeId, newFileName, file, isPdf) {
    const interpolateCustomFileUrl = `/api/documenttemplate/interpolatecustomfile/${appId}/file/?fileTypeId=${fileTypeId}&newAppFileName=${newFileName}&saveAsPdf=${isPdf}`;
    return this.uploadFiles(interpolateCustomFileUrl, [file]);
  }

  interpolateFileAndDownload(dataToPost, isPdf) {
    const url = `/api/documenttemplate/interpolate/download?saveAsPdf=${isPdf}`;

    this.postFile(url, dataToPost).pipe(take(1)).subscribe();
  }

  refreshFileRepository(appId, udfId, jurisdictionId, webhookName) {
    let data = {
      applicationId: appId,
      udfId: udfId,
      jurisdictionId: jurisdictionId,
    };
    return this.webhookService.postWebhook(webhookName, data).pipe(
      map((res: any) => {
        if (res && res.data) {
          let result = res.data.data === 'Successful';
          return result;
        } else {
          return false;
        }
      })
    );
  }
}
