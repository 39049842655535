import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
/* eslint-disable @nx/enforce-module-boundaries  */
// bug 66468 all imports
import { AccountService, NotificationService } from '@intellio/shared/services';
import { Observable, Subject, throwError } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { TaskAssignment, User, UserGroup } from '@intellio/shared/models';

@Component({
  selector: 'itc-add-task-assignment',
  templateUrl: './add-task-assignment.component.html',
  styleUrls: ['./add-task-assignment.component.scss'],
})
export class AddTaskAssignmentComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<AddTaskAssignmentComponent>,
    private accountService: AccountService,
    private notificationService: NotificationService,
    private formBuilder: FormBuilder
  ) {}
  destroyed$ = new Subject();
  selectedUsers: Observable<User[]>;
  selectedGroups: Observable<UserGroup[]>;
  newAssignment: TaskAssignment;
  users: User[];
  userGroups: UserGroup[];
  formGroup: UntypedFormGroup;
  loading = true;
  usersSubject = new Subject<string>();

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      userControl: [null],
      groupControl: [null],
    });
    this.getUsers();
    this.getUserGroups();
  }

  getUsers() {
    this.loading = true;
    this.usersSubject
      .pipe(
        debounceTime(250),
        startWith(''),
        filter((value: string) => value.trim().length > 0), // Only pass non-empty strings
        switchMap((value: string) =>
          this.accountService.getInternalUsers({
            search: value,
            enableSuggestions: true
          })
        ),
        map((response) => response.data.users),
        tap((users) => {
          this.users = users;
        })
      )
      .subscribe(
        () => {
          this.selectedUsers = this.formGroup?.controls[
            'userControl'
          ]?.valueChanges.pipe(
            startWith(''),
            tap((value) => {
              this.formGroup?.controls['userControl']?.setValue(value);
            }),
            takeUntil(this.destroyed$)
          );
        },
        (err: any) => {
          this.notificationService.open(err.error.message, 'error');
          return throwError(err);
        }
      );
  }

  getUserGroups() {
    this.loading = true;
    this.accountService.getAllUserGroups().subscribe(
      (data) => {
        this.userGroups = data.data;
        this.selectedGroups = this.formGroup?.controls[
          'groupControl'
        ]?.valueChanges.pipe(
          startWith(''),
          map((value) => {
            this.formGroup?.controls['groupControl']?.setValue(value);
            return this.searchGroup(value.toString());
          }),
          takeUntil(this.destroyed$)
        );
        this.loading = false;
      },
      (err: any) => {
        this.notificationService.open(err.error.message, 'error');
        return throwError(err);
      }
    );
  }

  searchUsers(value: string): void {
    const filter = value.toLowerCase();
    this.usersSubject.next(filter);
  }

  searchGroup(value: string): UserGroup[] {
    const searchString = value?.toLowerCase();
    return this.userGroups.filter((option) =>
      option.name.toLowerCase().includes(searchString)
    );
  }

  addAssignee() {
    const usersToAdd: TaskAssignment[] = [];
    const groupsToAdd: TaskAssignment[] = [];
    if (
      this.formGroup.controls['userControl'].value != null &&
      this.formGroup.controls['userControl'].value != ''
    ) {
      this.formGroup.controls['userControl'].value?.forEach((u) => {
        const user = this.users.find((f) => f.id == u);
        const newAssignment = {
          user: user,
          firstName: user.firstName,
          lastName: user.lastName,
          id: null,
          taskId: null,
          userId: user.id,
          initials:
            user.firstName.substring(0, 1) + user.lastName.substring(0, 1),
        };
        usersToAdd.push(newAssignment);
      });
    }

    if (
      this.formGroup.controls['groupControl'].value != null &&
      this.formGroup.controls['groupControl'].value != ''
    ) {
      this.formGroup.controls['groupControl'].value?.forEach((u) => {
        const group = this.userGroups.find((f) => f.id == u);
        const newAssignment = {
          group: group,
          id: null,
          taskId: null,
          groupId: group.id,
          groupName: group.name,
          initials: group.name.substring(0, 1),
        };
        groupsToAdd.push(newAssignment);
      });
    }

    this.dialogRef.close({ addedUsers: usersToAdd, addedGroups: groupsToAdd });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
