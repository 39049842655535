import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  SupplementalFormCategory,
  SupplementalFormConfigModel,
  SupplementalFormState,
  SupplementFormRepresentationModel,
  TechnicalReviewAutomationRunLogRepresentation,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { FormlyService } from './formly.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationSupplementalFormsService extends BaseService {
  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  getReviewsForApplication(appId: string) {
    const options = {};
    const url = `/api/applications/${appId}/forms`;
    return this.get<SupplementFormRepresentationModel[]>(url, options);
  }

  getSupplementalFormsConfig() {
    const url = `/api/reviewschemas/states`;
    return this.get<SupplementalFormConfigModel[]>(url);
  }

  getReviews(appId) {
    return this.getReviewsForApplication(appId);
  }

  saveReview(appId, statusId, content, versionNumber?) {
    let url = `/api/applications/${appId}/forms/${statusId}/save`;
    if (versionNumber != null) {
      url = url + `?versionNumber=${versionNumber}`;
    }
    return this.put(url, content);
  }

  submitReview(appId, statusId, content) {
    const url = `/api/applications/${appId}/forms/${statusId}/submit`;
    return this.put(url, content);
  }

  getAutomatedTechnicalReviewCalculationLog(appId, techReviewVersionNumber) {
    const url = `/api/applications/${appId}/calculationLogs/${techReviewVersionNumber}`;
    return this.get<TechnicalReviewAutomationRunLogRepresentation>(url);
  }

  unsubmitReview(appId, statusId, versionNumber) {
    let url = `/api/applications/${appId}/forms/${statusId}/unsubmit`;
    return this.put(url, versionNumber); //todo: check what this parameter should be
  }

  createReview(appId, statusId, versionName) {
    const url = `/api/applications/${appId}/forms/${statusId}/create/${versionName}`;
    return this.put(url, null); //todo: check what this parameter should be
  }

  getApplicationReviewHistory(appId: string, appStatusId: string) {
    const options = {};
    const url = `/api/applications/${appId}/forms/${appStatusId}/history`;
    return this.get<SupplementFormRepresentationModel[]>(url, options);
  }

  approveReview(appId: string, statusId: string, content) {
    const url = `/api/applications/${appId}/forms/${statusId}/approve`;
    return this.put(url, content);
  }

  unapproveReview(
    appId: string,
    statusId: string,
    toState: SupplementalFormState,
    content
  ) {
    let params = new HttpParams();
    params = params.append('formToState', toState);
    const options = {
      params: params,
    };
    const url = `/api/applications/${appId}/forms/${statusId}/unapprove`;
    return this.put(url, content, options);
  }

  getSupplementalFormCategories() {
    const url = `/api/supplementalformcategory`;
    return this.get<SupplementalFormCategory[]>(url);
  }

  getSupplementalFormCategoriesByAppType(appTypeId: string) {
    const url = `/api/supplementalformcategory/${appTypeId}`;
    return this.get<SupplementalFormCategory[]>(url);
  }
}
