<div class="modal">
  <div class="small">
    <h2 i18n mat-dialog-title>
      Calculate System Losses Breakdown
    </h2>
    <mat-dialog-content>
      <div>
        <form [formGroup]="systemLossForm">
          <div class="form-layout">
            <mat-form-field>
              <mat-label>Soiling(%)</mat-label>
              <input matInput type="number" [formControl]="soilingControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Shading(%)</mat-label>
              <input matInput type="number" [formControl]="shadingControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Snow(%)</mat-label>
              <input matInput type="number" [formControl]="snowControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Mismatch(%)</mat-label>
              <input matInput type="number" [formControl]="mismatchControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Wiring(%)</mat-label>
              <input matInput type="number" [formControl]="wiringControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Connections(%)</mat-label>
              <input matInput type="number" [formControl]="connectionsControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Light-Induced Degredation(%)</mat-label>
              <input matInput type="number" [formControl]="lightInducedDegredationControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Nameplate Rating(%)</mat-label>
              <input matInput type="number" [formControl]="nameplateRatingControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Age(%)</mat-label>
              <input matInput type="number" [formControl]="ageControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Availability(%)</mat-label>
              <input matInput type="number" [formControl]="availabilityControl">
            </mat-form-field>
            <mat-form-field>
              <mat-label>Estimated System Losses</mat-label>
              <input matInput type="number" [formControl]="systemLossesControl">
            </mat-form-field>
          </div>

        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button
        mat-raised-button
        [disabled]="false"
        class="primary-button"
        color="primary"
        (click)="reset()"
        i18n
      >
        Reset
      </button>
      <button
        mat-raised-button
        class="primary-button"
        color="primary"
        (click)="close()"
        i18n
      >
        Cancel
      </button>
      <button
      mat-raised-button
      class="primary-button"
      color="primary"
      (click)="save()"
      i18n
    >
      Save
    </button>
    </mat-dialog-actions>
  </div>
</div>
