import { Injectable } from '@angular/core';
import { gql, Subscription } from 'apollo-angular';


const APPLICATION_PAYMENTS_SUBSCRIPTION = gql`
  subscription paymentEvents($applicationId: String) {
  onApplicationPaymentEvent(input: { applicationId: $applicationId }) {
    data {
      applicationId
      paymentId
      paymentStatus
      description
    }
  }
}
`;

@Injectable({
  providedIn: 'root'
})
export class ApplicationPaymentSubscriptionService extends Subscription {

  document = APPLICATION_PAYMENTS_SUBSCRIPTION;
}
