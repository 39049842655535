import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import {
  CreateAccountModel,
  ChangePasswordModel,
  UpdateUserRequest,
  User,
  AccountsResponse,
  Role,
  SimpleUser,
  UserGroup,
  UserGroupAssignment,
} from '@intellio/shared/models';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends BaseService {
  readonly PAGE_SIZE = 25;
  private currentUserSubject$ = new BehaviorSubject<User>(null);
  private _currentUser: User;

  constructor(
    client: HttpClient,
    configService: AppConfigService,
    authService: AuthService,
    private roleService: RoleService
  ) {
    super(client, configService, authService);
  }

  get currentUser() {
    return this._currentUser;
  }

  getAccount() {
    const url = '/api/account';
    return this.get<User>(url, {}).pipe(
      tap((response) => {
        const user = response.data;
        user.roles = this.roleService.applyRoleMetadata(user.roles);

        //cache user object
        this.currentUserSubject$.next(user);
        this._currentUser = user;
        //return cached user value for subsequent access
      })
    );
  }

  registerUser(accountData: CreateAccountModel) {
    let registerUrl = '/api/account';

    if (accountData.Id) {
      registerUrl += `/${accountData.Id}`;
    }

    const options = {
      isExternal: true,
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.post<string>(registerUrl, accountData, options);
  }

  registerExternalUser(accountData) {
    const externalRegisterUrl = '/api/account/external';
    return this.post<string>(externalRegisterUrl, accountData);
  }

  registerInternalUser(accountData) {
    const internalRegisterUrl = '/api/account/internal';
    return this.post<string>(internalRegisterUrl, accountData);
  }

  resetPassword(password: string, userId: string, code: string) {
    const resetUrl = '/api/account/resetpassword';

    const options = {
      isExternal: true,
      headers: {
        'X-Allow-Anonymous': 'true',
      },
    };

    return this.post<string>(
      resetUrl,
      {
        userId,
        code,
        newPassword: password,
        confirmPassword: password,
      },
      options
    );
  }

  updateAccount(updatedUser: UpdateUserRequest) {
    const notificationPrefsChanged = updatedUser.notificationPreferences.some(
      (nPref) => {
        const existingPref = this.currentUser.notificationPreferences.find(
          (ePref) => ePref.notificationTypeId === nPref.notificationTypeId
        );
        return (
          existingPref.inApp !== nPref.inApp ||
          existingPref.email !== nPref.email
        );
      }
    );

    let notificationUpdate: any = of(null);
    if (notificationPrefsChanged) {
      const updateNotificationUrl = `/api/preferences/${updatedUser.id}`;
      notificationUpdate = this.put(
        updateNotificationUrl,
        updatedUser.notificationPreferences
      );
    }

    const url = '/api/account';

    return notificationUpdate.pipe(
      mergeMap(() => this.put<string>(url, updatedUser)),
      switchMap(() => this.getAccount())
    );
  }

  updateAccountWithEmail(updatedUser: UpdateUserRequest) {
    const notificationPrefsChanged = updatedUser.notificationPreferences.some(
      (nPref) => {
        const existingPref = this.currentUser.notificationPreferences.find(
          (ePref) => ePref.notificationTypeId === nPref.notificationTypeId
        );
        return (
          existingPref.inApp !== nPref.inApp ||
          existingPref.email !== nPref.email
        );
      }
    );

    let notificationUpdate: any = of(null);
    if (notificationPrefsChanged) {
      const updateNotificationUrl = `/api/preferences/${updatedUser.id}`;
      notificationUpdate = this.put(
        updateNotificationUrl,
        updatedUser.notificationPreferences
      );
    }

    const url = '/api/account';

    return notificationUpdate.pipe(
      mergeMap(() => this.put<string>(url, updatedUser))
    );
  }

  updatePassword(changePasswordData: ChangePasswordModel) {
    const url = '/api/account/changepassword';
    return this.post<string>(url, changePasswordData);
  }

  resendConfirmation() {
    const url = '/api/account/resendconfirmation/';
    return this.post<string>(url);
  }

  resendConfirmationToUser(userID) {
    const url = '/api/account/resendconfirmation/' + userID;
    return this.post<string>(url);
  }

  getAllAccounts() {
    const allAccountsUrl = '/api/account/all';
    return this.get<User[]>(allAccountsUrl);
  }

  getAllUserGroups() {
    const groupsUrl = '/api/usergroups';
    return this.get<UserGroup[]>(groupsUrl);
  }

  getUserGroupById(id) {
    const groupUrl = '/api/usergroups/' + id;
    return this.get<UserGroup>(groupUrl);
  }

  getUserGroupAssignments() {
    const groupsUrl = '/api/usergroups/assignments';
    return this.get<UserGroupAssignment[]>(groupsUrl);
  }

  deleteUserGroupAssignment(groupAssignment) {
    const deleteGroupUrl = '/api/usergroups';
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: groupAssignment.userId,
        groupId: groupAssignment.groupId,
        role: groupAssignment.role,
      },
    };
    return this.delete(deleteGroupUrl, options);
  }

  addUserGroupAssignment(groupAssignment) {
    const addGroupUrl = '/api/usergroups';
    return this.post(addGroupUrl, {
      userId: groupAssignment.userId,
      groupId: groupAssignment.groupId,
      role: groupAssignment.role,
    });
  }

  getFullAccounts(params) {
    const fullAccountsUrl = '/api/account/full';
    const config = {
      params: params,
    };
    return this.get<any>(fullAccountsUrl, config);
  }

  getInternalUsers(params?) {
    const userUrl = '/api/search/accounts';
    const config = {
      params: {
        isExternal: false,
        ...params,
      },
    };
    return this.get<AccountsResponse>(userUrl, config);
  }

  getSimpleInternalUsers() {
    const userUrl = '/api/account/internal/simple';
    return this.get<SimpleUser[]>(userUrl);
  }

  getExternalUsers(params?) {
    const userUrl = '/api/search/accounts';
    const config = {
      params: {
        isExternal: true,
        ...params,
      },
    };
    return this.get<AccountsResponse>(userUrl, config);
  }

  getSimpleExternalUsers() {
    const userUrl = '/api/account/external/simple';
    return this.get<SimpleUser[]>(userUrl);
  }

  getUnconfirmedEmails() {
    const url = '/api/account/unconfirmed';
    const config = { params: { justValidEmails: false } };
    return this.get<string[]>(url, config);
  }

  setOtherUserSettings(userID, settings) {
    const url = '/api/account/' + userID;
    return this.put(url, settings);
  }

  getSPOCRoles() {
    const url = '/api/account/spocroles';
    return this.get<Role[]>(url);
  }

  getAllRoles() {
    const url = '/api/account/roles';
    return this.get<Role[]>(url);
  }

  deleteUser(userID) {
    const deleteUrl = '/api/account/' + userID;
    return this.delete(deleteUrl);
  }

  getAccountById(userId) {
    const accountUrl = '/api/account/' + userId;
    return this.get<User>(accountUrl);
  }
}
