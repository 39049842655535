import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { GeocodeResultModel } from '@intellio/shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, combineLatest, startWith, takeUntil, takeWhile } from 'rxjs';
import { startsWith } from 'lodash';

@Component({
  selector: 'itc-system-loss-calculator',
  templateUrl: './system-loss-calculator.component.html',
  styleUrls: ['./system-loss-calculator.component.scss'],
})
export class SystemLossCalculatorComponent implements OnInit, OnDestroy {

  //Controls and their default values
  soilingDefault = 2;
  soilingControl = new FormControl(this.soilingDefault);

  shadingDefault = 3;
  shadingControl = new FormControl(this.shadingDefault);

  snowDefault = 0;
  snowControl = new FormControl(this.snowDefault);

  mismatchDefault = 2;
  mismatchControl = new FormControl(this.mismatchDefault);

  wiringDefault = 2;
  wiringControl = new FormControl(this.wiringDefault);

  connectionsDefault = 0.5;
  connectionsControl = new FormControl(this.connectionsDefault);

  lightInducedDegredationDefault = 1.5;
  lightInducedDegredationControl = new FormControl(this.lightInducedDegredationDefault);

  nameplateRatingDefault = 1;
  nameplateRatingControl = new FormControl(this.nameplateRatingDefault);

  ageDefault = 0;
  ageControl = new FormControl(this.ageDefault);

  availabilityDefault = 3;
  availabilityControl = new FormControl(this.availabilityDefault);

  systemLossesDefault = 14.08;
  systemLossesControl = new FormControl({
    value: this.systemLossesDefault,
    disabled: true
  });

  systemLossForm = new FormGroup({
    soiling : this.soilingControl,
    shading: this.shadingControl,
    snow: this.snowControl,
    mismatch: this.mismatchControl,
    wiring: this.wiringControl,
    connections: this.connectionsControl,
    lightInducedDegredation: this.lightInducedDegredationControl,
    nameplateRating: this.nameplateRatingControl,
    age: this.ageControl,
    availability: this.availabilityControl,
    systemLosses: this.systemLossesControl
  });

  active = false;
  destroyed$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<SystemLossCalculatorComponent>
  ) {}
  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
  ngOnInit(): void {

   this.soilingControl.setValue(this.data.soiling);
   this.shadingControl.setValue(this.data.shading);
   this.snowControl.setValue(this.data.snow);
   this.mismatchControl.setValue(this.data.mismatch);
   this.wiringControl.setValue(this.data.wiring);
   this.connectionsControl.setValue(this.data.connections);
   this.lightInducedDegredationControl.setValue(this.data.lightInducedDegredation);
   this.nameplateRatingControl.setValue(this.data.nameplateRating);
   this.ageControl.setValue(this.data.age);
   this.availabilityControl.setValue(this.data.availability);
   this.systemLossesControl.patchValue(this.data.systemLosses);

   const valueChangesObs = [
    this.soilingControl.valueChanges.pipe(startWith(this.data.soiling)),
    this.shadingControl.valueChanges.pipe(startWith(this.data.shading)),
    this.snowControl.valueChanges.pipe(startWith(this.data.snow)),
    this.mismatchControl.valueChanges.pipe(startWith(this.data.mismatch)),
    this.wiringControl.valueChanges.pipe(startWith(this.data.wiring)),
    this.connectionsControl.valueChanges.pipe(startWith(this.data.connections)),
    this.lightInducedDegredationControl.valueChanges.pipe(startWith(this.data.lightInducedDegredation)),
    this.nameplateRatingControl.valueChanges.pipe(startWith(this.data.nameplateRating)),
    this.ageControl.valueChanges.pipe(startWith(this.data.age)),
    this.availabilityControl.valueChanges.pipe(startWith(this.data.availability)),
   ];

   combineLatest(valueChangesObs).pipe(
    takeUntil(this.destroyed$)
   ).subscribe(
    {
      next: (arr) => {

        //calculation logic on page 10 of the pvwatts technical reference: https://www.nrel.gov/docs/fy14osti/62641.pdf
        const normalized = arr.map(el => 1 - (el/100.00));
        var product = 1;
        normalized.forEach(el => product = product * el);
        const sysLoss = 100 * (1 - product);
        const sysLossRounded = Math.round(sysLoss * 1000)/1000
        this.systemLossesControl.patchValue(sysLossRounded);
      }
    }
   )
  }

  close(): void {
    this.dialogRef.close({
      soiling : this.data.soiling,
      shading: this.data.hading,
      snow: this.data.snow,
      mismatch: this.data.mismatch,
      wiring: this.data.wiring,
      connections: this.data.connections,
      lightInducedDegredation: this.data.lightInducedDegredation,
      nameplateRating: this.data.nameplateRating,
      age: this.data.age,
      availability: this.data.availability,
      systemLosses: this.data.systemLosses,
      save: false,
    });
  }

  reset(): void{
    this.soilingControl.setValue(this.soilingDefault);
    this.shadingControl.setValue(this.shadingDefault);
    this.snowControl.setValue(this.snowDefault);
    this.mismatchControl.setValue(this.mismatchDefault);
    this.wiringControl.setValue(this.wiringDefault);
    this.connectionsControl.setValue(this.connectionsDefault);
    this.lightInducedDegredationControl.setValue(this.lightInducedDegredationDefault);
    this.nameplateRatingControl.setValue(this.nameplateRatingDefault);
    this.ageControl.setValue(this.ageDefault);
    this.availabilityControl.setValue(this.availabilityDefault);
    this.systemLossesControl.patchValue(this.systemLossesDefault);
  }

  save(): void {
    this.dialogRef.close({
      soiling : this.soilingControl.value,
      shading: this.shadingControl.value,
      snow: this.snowControl.value,
      mismatch: this.mismatchControl.value,
      wiring: this.wiringControl.value,
      connections: this.connectionsControl.value,
      lightInducedDegredation: this.lightInducedDegredationControl.value,
      nameplateRating: this.nameplateRatingControl.value,
      age: this.ageControl.value,
      availability: this.availabilityControl.value,
      systemLosses: this.systemLossesControl.value,
      save: true
    });
  }
}
