import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
// bug 66468
// eslint-disable-next-line
import {
  AccountService,
  AppCategoryService,
  NotificationService,
} from '@intellio/shared/services';
import {
  getAccount,
  getAccountFailure,
  getAccountSuccess,
  getAvailableCategoriesForUserFailure,
  getAvailableCategoriesForUserSuccess,
  getExternalUsers,
  getExternalUsersFailure,
  getExternalUsersSuccess,
  getSimpleExternalUsers,
  getSimpleExternalUsersFailure,
  getSimpleExternalUsersSuccess,
  getStatusChangeSchemas,
  getStatusChangeSchemasFailure,
  getStatusChangeSchemasSuccess,
  updateAccountCurrentAppCategory,
  updateAccountCurrentAppCategoryFailed,
  updateAccountCurrentAppCategorySucceeded,
  getInternalUsers,
  getInternalUsersSucceeded,
  getInternalUsersFailure,
} from './account.actions';
import { ApplicationCategory } from '@intellio/shared/models';
import { Router } from '@angular/router';

@Injectable()
export class AccountEffects {
  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private appCategoryService: AppCategoryService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccount),
      mergeMap(() =>
        this.accountService.getAccount().pipe(
          map((currentUser) =>
            getAccountSuccess({ currentUser: currentUser.data })
          ),
          catchError((error: any) => of(getAccountFailure({ error })))
        )
      )
    )
  );

  getAllAvailableCategoriesForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccountSuccess),
      mergeMap(() =>
        this.appCategoryService.getAvailableCategoriesForUser().pipe(
          map((availableCategories: ApplicationCategory[]) =>
            getAvailableCategoriesForUserSuccess({ availableCategories })
          ),
          catchError((error: any) => {
            this.notificationService.open(
              'Failed to retrieve available app categories for user. Try refreshing the page or contacting support.',
              'error'
            );
            return of(getAvailableCategoriesForUserFailure({ error }));
          })
        )
      )
    )
  );

  getExternalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getExternalUsers),
      mergeMap(() =>
        this.accountService.getExternalUsers().pipe(
          map((users) => getExternalUsersSuccess({ users: users.data.users })),
          catchError((error: any) => of(getExternalUsersFailure({ error })))
        )
      )
    )
  );

  getSimpleExternalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSimpleExternalUsers),
      mergeMap(() =>
        this.accountService.getSimpleExternalUsers().pipe(
          map((users) => getSimpleExternalUsersSuccess({ users: users.data })),
          catchError((error: any) =>
            of(getSimpleExternalUsersFailure({ error }))
          )
        )
      )
    )
  );

  getInternalUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getInternalUsers),
      mergeMap(({}) =>
        this.accountService.getInternalUsers().pipe(
          map((internalUsers) =>
            getInternalUsersSucceeded({
              internalUsers: internalUsers.data.users,
            })
          ),
          catchError((error: any) => of(getInternalUsersFailure({ error })))
        )
      )
    )
  );

  switchAppCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAccountCurrentAppCategory),
      mergeMap((action) =>
        this.appCategoryService
          .switchCurrentAppCategory(action.appCategory)
          .pipe(
            mapTo(
              updateAccountCurrentAppCategorySucceeded({
                appCategory: action.appCategory,
              })
            ),
            catchError((error: any) => {
              this.notificationService.open(
                'Failed to update current app category for user. Try refreshing the page or contacting support.',
                'error'
              );
              return of(updateAccountCurrentAppCategoryFailed({ error }));
            })
          )
      )
    )
  );

  updateStatusChangeSchemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAccountCurrentAppCategorySucceeded),
      mergeMap((action) =>
        this.appCategoryService
          .getSchemasForCategory(action.appCategory.id)
          .pipe(
            mapTo(
              getStatusChangeSchemas({
                appCategory: action.appCategory,
              })
            ),
            catchError((error: any) => {
              return of(getStatusChangeSchemasFailure({ error }));
            })
          )
      )
    )
  );

  getStatusChangeSchemas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccountSuccess),
      mergeMap((action) =>
        this.appCategoryService
          .getSchemasForCategory(action.currentUser.activeCategoryId)
          .pipe(
            map((schemas) => getStatusChangeSchemasSuccess({ schemas })),
            catchError((error: any) =>
              of(getStatusChangeSchemasFailure({ error }))
            )
          )
      )
    )
  );

  redirectUserOnCategorySwitch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAccountCurrentAppCategorySucceeded),
        tap(() => {
          this.router.navigate(['/applications']);
        })
      ),
    {
      dispatch: false,
    }
  );
}