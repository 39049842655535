import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TaskQueryParams,
  Task,
  TaskQueueResponse,
  TaskTemplate,
} from '@intellio/shared/models';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { BaseService } from './base-service.service';
import { BehaviorSubject, Subject, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppTasksService extends BaseService {
  readonly PAGE_SIZE = 25;

  private readonly defaultTasksResponse = {
    tasks: [],
    totalTasks: 0,
  };

  private tasksResponseSubject$ = new BehaviorSubject<TaskQueueResponse>(
    this.defaultTasksResponse
  );
  private tasksSearchResponseSubject$ = new BehaviorSubject<TaskQueueResponse>(
    this.defaultTasksResponse
  );
  getTasksSubject$ = new Subject<TaskQueryParams>();
  searchTasksSubject$ = new Subject<TaskQueryParams>();

  get tasksData$() {
    return this.tasksResponseSubject$.asObservable();
  }

  get taskIndexData$() {
    return this.tasksSearchResponseSubject$.asObservable();
  }

  constructor(
    protected client: HttpClient,
    protected configService: AppConfigService,
    protected authService: AuthService
  ) {
    super(client, configService, authService);
  }

  deleteTask(taskId) {
    const appTaskUrl = '/api/tasks/' + taskId;
    return this.delete(appTaskUrl);
  }

  get getSearchTasks$() {
    // Components should subscribe to this getter which runs when the respective subject gets a new value
    // The subject takes params for the http request and forwards those onto the http request in a switchMap
    // This behavior results in old requests being cancelled
    return this.searchTasksSubject$.pipe(
      switchMap((params) => this.searchTasksForUser(params))
    );
  }

  get getTasks$() {
    // Components should subscribe to this getter which runs when the respective subject gets a new value
    // The subject takes params for the http request and forwards those onto the http request in a switchMap
    // This behavior results in old requests being cancelled
    return this.getTasksSubject$.pipe(
      switchMap((params) => this.getTasksForUser(params.userId, params))
    );
  }

  getAppTasks(appId, taskParams) {
    const appTasksURL = '/api/tasks/application/' + appId;
    const params = new HttpParams();
    return this.get<any>(appTasksURL, { params: taskParams });
  }

  searchTasksForUser(taskParams) {
    const appTasksUserUrl = '/api/search/tasks?userId=' + taskParams.userId;
    return this.get<TaskQueueResponse>(appTasksUserUrl, {
      params: taskParams,
    }).pipe(
      map(
        (response) => {
          this.tasksSearchResponseSubject$.next(response.data);
          return response.data;
        },
        (err) => {
          this.tasksSearchResponseSubject$.next(this.defaultTasksResponse);
        }
      )
    );
  }

  getTasksForUser(userId, taskParams) {
    const appTasksUserUrl = '/api/tasks?userId=' + userId;
    return this.get<TaskQueueResponse>(appTasksUserUrl, {
      params: taskParams,
    }).pipe(
      map(
        (response) => {
          this.tasksResponseSubject$.next(response.data);
          return response.data;
        },
        (err) => {
          this.tasksResponseSubject$.next(this.defaultTasksResponse);
        }
      )
    );
  }

  getTask(taskId) {
    const taskUrl = '/api/tasks/' + taskId;
    return this.get<Task>(taskUrl);
  }

  updateTask(taskId, updatedTaskInfo) {
    const appTaskUrl = '/api/tasks/' + taskId;
    return this.put<Task>(appTaskUrl, updatedTaskInfo);
  }

  createTask(taskData) {
    const appTaskUrl = '/api/tasks';
    return this.post<string>(appTaskUrl, taskData);
  }

  deleteTaskAssignment(assignmentId) {
    const assignmentUrl = '/api/tasks/assignments/' + assignmentId;
    return this.delete(assignmentUrl);
  }

  createTaskAssignment(taskId, userId, groupId) {
    const assignmentUrl = '/api/tasks/assignments/';
    return this.post<string>(assignmentUrl, { taskId, userId, groupId });
  }

  createBulkTaskAssignment(chosenUserId, taskIdsList) {
    const reassignUrl = '/api/tasks/assignments/bulk/';
    return this.post<string>(reassignUrl, { chosenUserId, taskIdsList });
  }

  getTemplates(applicationTypeId) {
    const templateUrl = '/api/task/template/type/' + applicationTypeId;
    return this.get<TaskTemplate[]>(templateUrl);
  }
}
