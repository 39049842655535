import { Pipe, PipeTransform } from '@angular/core';
import { Message, User, SimpleUser } from '@intellio/shared/models';

@Pipe({
  name: 'isMessageCurrentTeam',
})
export class IsMessageCurrentTeamPipe implements PipeTransform {
  transform(
    message: Message,
    isCurrentUserInternal: boolean,
    currentUserEmail: string    
  ): boolean {
    if (isCurrentUserInternal) {
      return (
        !message.senderIsExternalUser
      );
    } else {
      return currentUserEmail === message.senderUserName;
    }
  }
}
