<div class="drawer app-tasks-filter">
  <div class="drawer__content mat-typography">
    <div class="drawer__title">Filter Tasks</div>
    <form class="main-form" [formGroup]="formGroup">
      <div class="drawer__form-field" *ngIf="!noTaskStatus">
        <strong i18n>Filter Name</strong>
        <mat-form-field appearance="fill">
          <input
            formControlName="name"
            matInput
            placeholder="Filter Name"
            i18n-placeholder
          />
        </mat-form-field>
      </div>
      <div class="drawer__form-field" *ngIf="!noTaskStatus">
        <strong i18n>Task Status</strong>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="status"
            placeholder="Task Status"
            multiple="true"
          >
            <mat-option value="NotStarted">Not Started</mat-option>
            <mat-option value="InProgress">In Progress</mat-option>
            <mat-option value="Completed">Completed</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Priority</strong></mat-label>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="priority"
            placeholder="Priority"
            multiple="true"
          >
            <mat-select-trigger *ngIf="formGroup.controls['priority'].value">
              <mat-icon
                *ngIf="formGroup.controls['priority'].value !== ''"
                class="selected-priority-icon"
                [svgIcon]="formGroup.controls['priority'].value + 'Priority'"
              ></mat-icon>
              {{ formGroup.controls['priority'].value }}
            </mat-select-trigger>
            <mat-option value=""></mat-option>
            <mat-option value="Low"
              ><mat-icon class="priority-icon" svgIcon="LowPriority"></mat-icon
              >Low</mat-option
            >
            <mat-option value="Medium"
              ><mat-icon
                class="priority-icon"
                svgIcon="MediumPriority"
              ></mat-icon
              >Medium</mat-option
            >
            <mat-option value="High"
              ><mat-icon class="priority-icon" svgIcon="HighPriority"></mat-icon
              >High</mat-option
            >
            <mat-option value="Critical"
              ><mat-icon
                class="priority-icon"
                svgIcon="CriticalPriority"
              ></mat-icon
              >Critical</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field assignee-list">
        <div class="assignees-title">
          <mat-label><strong i18n>Assignee</strong></mat-label>
          <mat-icon (click)="addAssigneeDialog()" *ngIf="anotherAssignee"
            >add</mat-icon
          >
        </div>
        <div class="in-place-spinner-wrapper" *ngIf="assigneesLoading !== 0">
          <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="assigneesLoading === 0">
          <div
            class="assignees"
            *ngFor="let assignment of formGroup.controls['assignees'].value"
          >
            <div class="assignee">
              <div class="assignee-icon">{{ assignment.initials }}</div>
              <div>{{ assignment.user?.fullName ?? assignment.group?.name }}</div>
            </div>
            <div class="assignees__delete" (click)="deleteAssignment(assignment)">
              <mat-icon>delete</mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Due Before</strong></mat-label>
        <mat-form-field appearance="fill">
          <input
            formControlName="dueBefore"
            matInput
            readonly
            placeholder="MM/DD/YYYY"
            i18n-placeholder
            [matDatepicker]="beforePicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="beforePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #beforePicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Due After</strong></mat-label>
        <mat-form-field appearance="fill">
          <input
            formControlName="dueAfter"
            matInput
            placeholder="MM/DD/YYYY"
            i18n-placeholder
            [matDatepicker]="afterPicker"
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="afterPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #afterPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Application Status</strong></mat-label>
        <mat-form-field appearance="fill">
          <mat-select
            formControlName="applicationStatusId"
            placeholder="Application Status"
          >
            <mat-option
              *ngFor="let status of statusList"
              [value]="status.statusId"
            >
              {{ status.statusName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="drawer__form-field">
        <mat-label><strong i18n>Search</strong></mat-label>
        <mat-form-field appearance="fill">
          <input
            formControlName="search"
            matInput
            placeholder="Search"
            i18n-placeholder
          />
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="drawer__actions">
    <button
      mat-button
      i18n
      class="drawer__actions__button secondary-button"
      (click)="closeDrawer()"
      aria-roledescription="close drawer button."
    >
      Cancel
    </button>
    <button
      class="drawer__actions__button primary-button"
      mat-button
      i18n
      (click)="applyModalFilters()"
    >
      Apply
    </button>
    <ng-container *ngIf="performantMode">
      <button
        *ngIf="currentFilter.id >= 0"
        class="drawer__actions__button primary-button"
        [disabled]="formGroup.invalid"
        mat-button
        i18n
        (click)="applyModalFilters('saveExisting')"
      >
        Update Filter
      </button>
      <button
        class="drawer__actions__button primary-button"
        [disabled]="formGroup.invalid"
        mat-button
        i18n
        (click)="applyModalFilters('saveNew')"
      >
        Save new Filter
      </button>
    </ng-container>
  </div>
</div>
