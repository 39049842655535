import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
//import { DataPersistence } from '@nx/angular';
// bug 66468
// eslint-disable-next-line
import {
  // eslint-disable-line
  ApplicationsService,
  AppUserService, // eslint-disable-line
  ApplicationService,
  ChangeStatusService,
  BusinessRolesService,
  AnalyticsService,
  AppUdrService,
  ApplicationFilesService,
} from '@intellio/shared/services';
import {
  createApplication,
  createApplicationFailure,
  createApplicationSuccess,
  getApplication,
  getApplicationFailure,
  getApplicationSuccess,
  updateApplicationOwner,
  updateApplicationOwnerSuccess,
  updateApplicationSpoc,
  updateApplicationSpocFailure,
  updateApplicationSpocSuccess,
  updateApplicationType,
  updateApplicationTypeSuccess,
  updateApplicationUDFs,
  updateApplicationUDFsSuccess,
  updateApplicationDetails,
  updateApplicationDetailsSuccess,
  updateApplicationArchive,
  updateApplicationArchiveSuccess,
  updateApplicationArchiveFailure,
  updateApplicationStatus,
  updateApplicationStatusSuccess,
  updateApplicationStatusFailure,
  updateApplicationBusinessRoles,
  updateApplicationBusinessRolesSuccess,
  updateApplicationBusinessRolesFailure,
  updateApplicationContent,
  updateApplicationContentSuccess,
  createApplicationAssociation,
  createApplicationAssociationSucceeded,
  getApplicationUDRs,
  getApplicationUDRsSucceeded,
  getApplicationUDRsFailure,
  updateApplicationUDRs,
  updateApplicationUDRsFailed,
  updateApplicationUDRsSucceeded,
  updateApplicationFileRepository,
  updateApplicationFileRepositorySucceeded,
  updateApplicationFileRepositoryFailed,
  getApplicationTypes,
  getApplicationTypesSucceeded,
  getApplicationTypesFailure,
  getStatuses,
  getStatusesSucceeded,
  getStatusesFailure,
  getAllApplicationAlerts,
  getAllApplicationAlertsSucceeded,
  getAllApplicationAlertsFailure,
} from './application.actions';
import { catchError, concatMap, map, mergeMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { assignModelValue } from '@ngx-formly/core/lib/utils';
import { result } from 'lodash';
import {
  ApplicationCreationResult,
  ApplicationFile,
} from '@intellio/shared/models';

@Injectable()
export class ApplicationEffects {
  updateApplicationUDFs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationUDFs),
      mergeMap(({ applicationId, udfs }) =>
        forkJoin([
          of(udfs),
          this.appUserService.updateUDFs(udfs, applicationId),
        ]).pipe(map(([udfs]) => updateApplicationUDFsSuccess({ udfs })))
      )
    )
  );

  updateApplicationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationDetails),
      mergeMap(({ applicationId, model }) =>
        forkJoin([
          of(model),
          this.applicationService.updateAppInfoFromModel(applicationId, model),
        ]).pipe(map(([model]) => updateApplicationDetailsSuccess({ model })))
      )
    )
  );

  updateApplicationType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationType),
      mergeMap(
        ({
          applicationId,
          typeId,
          typeName,
          detail,
          maxCapacity,
          minCapacity,
        }) =>
          forkJoin([
            of(typeId),
            of(typeName),
            of(detail),
            of(maxCapacity),
            of(minCapacity),
            this.applicationService.updateApplicationType(
              applicationId,
              typeId
            ),
          ]).pipe(
            map(([typeId, typeName, detail, maxCapacity, minCapacity]) =>
              updateApplicationTypeSuccess({
                typeId,
                typeName,
                detail,
                maxCapacity,
                minCapacity,
              })
            )
          )
      )
    )
  );

  updateApplicationOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationOwner),
      mergeMap(({ applicationIds, owner, muteNotification }) =>
        forkJoin([
          of(owner),
          this.applicationsService.updateOwner({
            applicationIds,
            muteNotification,
            newOwnerUsername: owner.email,
          }),
        ]).pipe(map(([owner]) => updateApplicationOwnerSuccess({ owner })))
      )
    )
  );

  updateApplicationContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationContent),
      mergeMap(({ applicationId, content }) =>
        this.applicationsService
          .saveApplication(applicationId, content, null)
          .pipe(map(() => updateApplicationContentSuccess({ content })))
      )
    )
  );

  createApplicationAssociation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createApplicationAssociation),
      mergeMap(({ applicationId, associatedIds }) =>
        this.applicationsService
          .createApplicationAssociation(applicationId, associatedIds)
          .pipe(
            map((associatedId) =>
              createApplicationAssociationSucceeded({ associatedId })
            )
          )
      )
    )
  );

  updateApplicationSpoc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationSpoc),
      mergeMap(({ applicationIds, assignedSpoc, muteNotification }) =>
        forkJoin([
          of(assignedSpoc),
          this.applicationsService.updateSPOC({
            applicationIds,
            muteNotification,
            newInitialSPOCUserName: assignedSpoc.email,
          }),
        ]).pipe(
          map(([assignedSpoc]) =>
            updateApplicationSpocSuccess({ assignedSpoc })
          ),
          catchError((error: any) =>
            of(updateApplicationSpocFailure({ error }))
          )
        )
      )
    )
  );

  updateApplicationBusinessRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationBusinessRoles),
      mergeMap(({ applicationId, payload }) =>
        this.businessRolesService
          .updateBusinessRoles(applicationId, payload)
          .pipe(
            map((businessRoles) =>
              updateApplicationBusinessRolesSuccess({ businessRoles })
            ),
            catchError((error: any) =>
              of(updateApplicationBusinessRolesFailure({ error }))
            )
          )
      )
    )
  );

  createApplication$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createApplication),
      mergeMap(({ payload }) =>
        this.applicationsService.createApplication(payload).pipe(
          map((result) => {
            this.analyticsService.logEvent('submitApplicationLogSuccess', {
              appId: result?.applicationId,
              payload: payload,
            });
            return createApplicationSuccess({ result });
          }),
          catchError((error) => of(createApplicationFailure({ error })))
        )
      )
    )
  );

  updateApplicationArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationArchive),
      mergeMap(({ archiveStatus, applicationId }) =>
        forkJoin([
          of(archiveStatus),
          this.applicationsService.updateArchiveStatus(
            archiveStatus,
            applicationId
          ),
        ]).pipe(
          map(([archiveStatus]) =>
            updateApplicationArchiveSuccess({ archiveStatus })
          ),
          catchError((error: any) =>
            of(updateApplicationArchiveFailure({ error }))
          )
        )
      )
    )
  );

  updateApplicationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationStatus),
      mergeMap(
        ({
          applicationId,
          newStatusId,
          fileNames,
          postBody,
          muteNotification,
          attachFilesToEmail,
          newStatusName,
          newOwnerId,
          taskConfigs
        }) =>
          forkJoin([
            of(newStatusId),
            of(newStatusName),
            this.changeStatusService.updateStatusChange(
              applicationId,
              newStatusId,
              fileNames,
              postBody,
              muteNotification,
              attachFilesToEmail,
              newOwnerId,
              taskConfigs
            ),
          ]).pipe(
            map(([newStatusId, newStatusName]) =>
              updateApplicationStatusSuccess({ newStatusId, newStatusName })
            ),
            catchError((error: any) =>
              of(updateApplicationStatusFailure({ error }))
            )
          )
      )
    )
  );

  getApplicationUDRs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApplicationUDRs),
      mergeMap(
        ({ appId, udrType, hasParent, includeUDRs, detailId, fetchDepth }) =>
          forkJoin([
            of(detailId),
            this.appUdrService.getAppUDRDetails(
              appId,
              udrType,
              hasParent,
              includeUDRs,
              detailId,
              fetchDepth
            ),
          ]).pipe(
            map(([detailId, result]) =>
              result
                ? getApplicationUDRsSucceeded({ detailId, result: result })
                : getApplicationUDRsFailure({ error: result })
            ),
            catchError((error: any) => of(getApplicationUDRsFailure({ error })))
          )
      )
    )
  );

  updateApplicationUDRs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationUDRs),
      mergeMap(({ appId, webhookName }) =>
        this.appUdrService.refreshUDRS(appId, webhookName).pipe(
          map((result) =>
            result
              ? updateApplicationUDRsSucceeded({ result })
              : updateApplicationUDRsFailed({ error: result })
          ),
          catchError((error: any) => of(updateApplicationUDRsFailed({ error })))
        )
      )
    )
  );

  updateApplicationFileRepository$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateApplicationFileRepository),
      mergeMap(({ appId, udfId, jurisdictionId, webhookName }) =>
        this.applicationFilesService
          .refreshFileRepository(appId, udfId, jurisdictionId, webhookName)
          .pipe(
            map((result: boolean) =>
              result
                ? updateApplicationFileRepositorySucceeded({ result })
                : updateApplicationFileRepositoryFailed({ error: result })
            ),
            catchError((error: any) =>
              of(updateApplicationFileRepositoryFailed({ error }))
            )
          )
      )
    )
  );

  getApplication$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getApplication),
        mergeMap(({ applicationId }) =>
          this.applicationsService.getApplicationById(applicationId).pipe(
            map((application) => getApplicationSuccess({ application })),
            catchError((error: any) => of(getApplicationFailure({ error })))
          )
        )
      )
    // this.dataPersistence.fetch(getApplication, {
    //   run: (action: typeof getApplication, state: ApplicationPartialState) => {
    //     // Your custom service 'load' logic goes here. For now just return a success action...
    //     return this.applicationsService
    //       .getApplicationById(action.applicationId)
    //       .pipe(map((application) => getApplicationSuccess({ application })));
    //   },

    //   onError: (action: typeof getApplication, error) => {
    //     console.error('Error', error);
    //     return getApplicationFailure({ error });
    //   },
    // })
  );

  getApplicationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getApplicationTypes),
      mergeMap(({}) =>
        this.applicationsService.getApplicationTypesForCurrentCategory().pipe(
          map((applicationTypes) =>
            getApplicationTypesSucceeded({
              applicationTypes: applicationTypes.data,
            })
          ),
          catchError((error: any) => of(getApplicationTypesFailure({ error })))
        )
      )
    )
  );

  getStatuses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getStatuses),
      mergeMap(({}) =>
        this.applicationsService.getAllApplicationStatuses().pipe(
          map((statuses) => getStatusesSucceeded({ statuses: statuses.data })),
          catchError((error: any) => of(getStatusesFailure({ error })))
        )
      )
    )
  );

  getAllApplicationAlerts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllApplicationAlerts),
      mergeMap(({}) =>
        this.applicationsService.getAllApplicationAlerts().pipe(
          map((allApplicationAlerts) =>
            getAllApplicationAlertsSucceeded({
              allApplicationAlerts: allApplicationAlerts.data,
            })
          ),
          catchError((error: any) =>
            of(getAllApplicationAlertsFailure({ error }))
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private applicationsService: ApplicationsService,
    private appUserService: AppUserService,
    private applicationService: ApplicationService,
    private changeStatusService: ChangeStatusService,
    private businessRolesService: BusinessRolesService,
    private analyticsService: AnalyticsService,
    private appUdrService: AppUdrService,
    private applicationFilesService: ApplicationFilesService
  ) {}
}
