import { TaskFilters } from './task-filters.model';

export class TaskQueryParams extends TaskFilters {
  userId: string;
  includeGroupTasks?: boolean;
  showCompleted?: boolean;
  sort?: string;
  asc: boolean;
  skip: number;
  take: number;
  exactMatch: boolean;
}
