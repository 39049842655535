import { ApplicationTypeStatusFileType } from "./application-type-status-file-type.model";

export class ApplicationStatus {
  allowsExternalApplicationEdits: boolean;
  archiveApplicationOnStatusChange: boolean;
  customerTypeStatusActivityDescription: string;
  customerTypeStatusDescription: string;
  daysRemainingAlertThreshold: number;
  description: string;
  fileTypes: ApplicationTypeStatusFileType[];
  groupId: string;
  groupName: string;
  hideFromExternalUsers: boolean;
  isActive: boolean;
  isInitialStatusForType: boolean;
  maxDuration: number;
  milestoneOrder: number;
  ownerChangeIsSuggested: boolean;
  statusChangePostTemplate: string;
  statusId: string;
  statusIsBlockedForStatusChange: boolean;
  statusName: string;
  statusType: string;
  takesPrecedenceForMilestone: boolean;
  frequency: number;
  recommended: boolean;
  category: string;
}
