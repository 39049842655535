export class ApplicationTypeStatusFileType {
    applicationStatusId?: string;
    applicationTypeId?: string;
    confirmationConfig: string;
    confirmationConfigId: string;
    fileTypeId: string;
    name: string;
    requiresConfirmation: boolean;
    recommendedForStatusChange: boolean;
    id: number;
    hasMultipleFileAttachments: boolean;
}
  